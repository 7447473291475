import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { TeamLogo } from "../../components/team/TeamLogo";
import { GameWinProbBar } from "./GameWinProbBar";
import {
  dateFormatLong,
  decFormat,
  decFormat2,
  timeFormat,
} from "../../util/Format";
import { useWindowSize } from "../../util/Hooks";
import { UserPreferenceContext } from "../../UserContext";

export function GamePageHeader(props: {
  gameId: string | undefined;
  homeTeamId: number;
  awayTeamId: number;
  homeAbbr: string;
  awayAbbr: string;
  ptsByQuarter: Record<
    number,
    {
      homePts: number;
      awayPts: number;
    }
  >;
  xPPPHome: number | null;
  xPPPAway: number | null;
  xPtsHome: number | null;
  xPtsAway: number | null;
  xWinPctHome: number | null;
  xWinPctAway: number | null;
  xBaselineWinPctHome: number | null;
  gameDateTimeStr: string | null;
  arenaname: string | null;
  location: string | null;
  lastUpdatedStr: string | undefined;
  homeTeamContent?: React.ReactNode;
  awayTeamContent?: React.ReactNode;
  seriesContent?: React.ReactNode;
}) {
  const {
    gameId,
    homeTeamId,
    awayTeamId,
    homeAbbr,
    awayAbbr,
    ptsByQuarter,
    xPPPHome,
    xPPPAway,
    xPtsAway,
    xPtsHome,
    xWinPctHome,
    xWinPctAway,
    xBaselineWinPctHome,
    gameDateTimeStr,
    arenaname,
    location,
    lastUpdatedStr,
    homeTeamContent,
    awayTeamContent,
    seriesContent,
  } = props;

  const { width } = useWindowSize();
  const isMobile = width < 768;

  // HACK: Basically we want this to be aligned center when the away and home
  // data are in a row. When they are stacked vertically we want this to be
  // left aligned with some specified margin.
  // Currently this behavior happens at screen width of < 708px.
  const teamsVerticallyStacked = width < 708;

  const homePts = Object.values(ptsByQuarter).reduce(
    (a, b) => a + b.homePts,
    0
  );
  const awayPts = Object.values(ptsByQuarter).reduce(
    (a, b) => a + b.awayPts,
    0
  );

  // Playoff game's start with a 4.
  const isPlayoffs = gameId && gameId[0] === "4";
  // If its a playoff game the series id is the game id but the last digit is 0.
  const seriesId = gameId ? gameId.slice(0, -1) + "0" : undefined;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: isMobile ? 0 : 24,
        }}
      >
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          {xWinPctHome &&
            xWinPctAway &&
            !teamsVerticallyStacked &&
            xBaselineWinPctHome && (
              <WinProbBar
                teamsVerticallyStacked={false}
                xWinPctHome={xWinPctHome}
                xWinPctAway={xWinPctAway}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                homeTeamName={homeAbbr}
                awayTeamName={awayAbbr}
                baselinexWinPctHome={xBaselineWinPctHome}
              />
            )}
          <PageHeaderTeam
            teamsVerticallyStacked={teamsVerticallyStacked}
            isHome={false}
            teamId={awayTeamId}
            pts={awayPts}
            xPts={xPtsAway}
            xPPP={xPPPAway}
            teamContent={awayTeamContent || undefined}
            xWinPct={xWinPctAway}
          />
          {xWinPctHome &&
            xWinPctAway &&
            teamsVerticallyStacked &&
            xBaselineWinPctHome && (
              <WinProbBar
                teamsVerticallyStacked={true}
                xWinPctHome={xWinPctHome}
                xWinPctAway={xWinPctAway}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                homeTeamName={homeAbbr}
                awayTeamName={awayAbbr}
                baselinexWinPctHome={xBaselineWinPctHome}
              />
            )}
          <PageHeaderTeam
            teamsVerticallyStacked={teamsVerticallyStacked}
            isHome={true}
            teamId={homeTeamId}
            pts={homePts}
            xPts={xPtsHome}
            xPPP={xPPPHome}
            teamContent={homeTeamContent || undefined}
            xWinPct={xWinPctHome}
          />
        </div>
        <div style={{ display: "inline-block", verticalAlign: "top" }}>
          <GameQuarterPts
            homeAbbr={homeAbbr}
            awayAbbr={awayAbbr}
            homePts={homePts}
            awayPts={awayPts}
            ptsByQuarter={ptsByQuarter}
          />
        </div>
        <div style={{ display: "inline-block" }}>{seriesContent}</div>
      </div>
      {gameDateTimeStr && arenaname && location && (
        <div
          style={{
            display: "flex",
            gap: 8,
            fontSize: 13,
            margin: "0 -30px 8px",
            padding: "3px 15px",
            color: "rgba(255,255,255,.5)",
          }}
        >
          {`${dateFormatLong(new Date(gameDateTimeStr))} at ${timeFormat(
            new Date(gameDateTimeStr)
          )} in ${arenaname}, ${location}`}
          {isPlayoffs && (
            <>
              <span>|</span>
              <Link to={`/series/${seriesId}`}>See Full Series</Link>
            </>
          )}
        </div>
      )}
      {lastUpdatedStr && (
        <div
          style={{
            fontSize: 13,
            margin: "0 -30px 8px",
            padding: "3px 15px",
            color: "rgba(255,255,255,.5)",
          }}
        >
          {lastUpdatedStr}
        </div>
      )}
    </>
  );
}

function WinProbBar(props: {
  teamsVerticallyStacked: boolean;
  xWinPctHome: number;
  xWinPctAway: number;
  homeTeamId: number;
  awayTeamId: number;
  homeTeamName: string;
  awayTeamName: string;
  baselinexWinPctHome: number;
}) {
  const {
    teamsVerticallyStacked,
    xWinPctHome,
    xWinPctAway,
    homeTeamId,
    awayTeamId,
    homeTeamName,
    awayTeamName,
    baselinexWinPctHome,
  } = props;
  return (
    <div
      style={{
        // This max width should match the width of team header els beneath it.
        maxWidth: 648,
        textAlign: teamsVerticallyStacked ? undefined : "center",
        marginLeft: teamsVerticallyStacked ? 40 : undefined,
        marginBottom: teamsVerticallyStacked ? 0 : -24,
        marginTop: teamsVerticallyStacked ? 8 : 0,
      }}
    >
      <GameWinProbBar
        xWinPctHome={xWinPctHome}
        xWinPctAway={xWinPctAway}
        homeTeamId={homeTeamId}
        awayTeamId={awayTeamId}
        homeTeamName={homeTeamName}
        awayTeamName={awayTeamName}
        baselinexWinPctHome={baselinexWinPctHome}
      />
    </div>
  );
}

function PageHeaderTeam(props: {
  teamsVerticallyStacked: boolean;
  isHome: boolean;
  teamId: number;
  pts: number;
  xPts: number | null;
  xPPP: number | null;
  xWinPct: number | null;
  teamContent?: React.ReactNode;
}) {
  const {
    teamsVerticallyStacked,
    isHome,
    pts,
    xPPP,
    xPts,
    teamId,
    xWinPct,
    teamContent,
  } = props;
  const logoBlur = !!useContext(UserPreferenceContext)["Logo Blur"];
  const expected = (
    <div
      style={{
        width: 136,
        fontSize: 20,
        color: "rgba(255,255,255,.8)",
        background: "rgba(255,255,255,.11)",
        padding: 8,
        // When not vertically stacked push down the xPts so winProb can fill
        // the space. Otherwise only use 10pt margin. Also if there is no win
        // prob then use normal margin.
        marginTop: xWinPct === null || teamsVerticallyStacked ? 10 : 34,
        borderRadius: 5,
      }}
    >
      <div>
        xPTS:{" "}
        <span style={{ color: "#fff", fontWeight: 600 }}>
          {decFormat(xPts) || "---"}
        </span>
      </div>
      <div>
        xPPP:{" "}
        <span style={{ color: "#fff", fontWeight: 600 }}>
          {decFormat2(xPPP) || "---"}
        </span>
      </div>
    </div>
  );

  const logo = (
    <Link to={`/team/${teamId}`}>
      <TeamLogo idsId={teamId} blur={logoBlur} />
    </Link>
  );

  const ptsDiv = (
    <div style={{ marginRight: 16, marginLeft: 16 }}>
      <div
        style={{
          fontSize: 40,
          width: 60,

          textAlign: "left",
        }}
      >
        {pts}
      </div>
      <div
        style={{
          textAlign: isHome ? "right" : "left",
          color: "rgba(255,255,255,.5)",
          textTransform: "uppercase",
          fontSize: 12,
        }}
      >
        {teamContent ? null : isHome ? "Home" : "Away"}
      </div>
    </div>
  );

  if (!isHome) {
    return (
      <div
        style={{
          display: "inline-block",
          verticalAlign: "top",
          marginRight: 16,
        }}
      >
        <div style={{ display: "flex" }}>
          {logo}
          {ptsDiv}
          {expected}
        </div>
        {teamContent && (
          <div
            style={{
              textAlign: "left",
              color: "rgba(255,255,255,.5)",
              textTransform: "uppercase",
              fontSize: 12,
            }}
          >
            {teamContent}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "inline-block",
          verticalAlign: "top",
          marginLeft: 16,
        }}
      >
        <div style={{ display: "flex" }}>
          {expected}
          {ptsDiv}
          {logo}
        </div>
        {teamContent && (
          <div
            style={{
              textAlign: "right",
              color: "rgba(255,255,255,.5)",
              textTransform: "uppercase",
              fontSize: 12,
            }}
          >
            {teamContent}
          </div>
        )}
      </div>
    );
  }
}

function GameQuarterPts(props: {
  homeAbbr: string;
  awayAbbr: string;
  homePts: number;
  awayPts: number;
  ptsByQuarter: Record<
    number,
    {
      homePts: number;
      awayPts: number;
    }
  >;
}) {
  const { homeAbbr, awayAbbr, homePts, awayPts, ptsByQuarter } = props;

  const headers = [],
    awayQuarters = [],
    homeQuarters = [];
  const numQuarters = Object.keys(ptsByQuarter).length;

  for (let i = 0; i < numQuarters; i++) {
    let quarterName;
    if (i > 3) {
      if (numQuarters === 5) {
        quarterName = "OT";
      } else {
        quarterName = `OT${i - 3}`;
      }
    } else {
      quarterName = i + 1;
    }

    const ptsAtCurQ = ptsByQuarter[i + 1];
    const awayPtsInQ = ptsAtCurQ ? ptsAtCurQ.awayPts : 0;
    const homePtsInQ = ptsAtCurQ ? ptsAtCurQ.homePts : 0;

    headers.push(
      <th key={i} style={{ padding: 7, verticalAlign: "top" }}>
        {quarterName}
      </th>
    );
    awayQuarters.push(
      <td key={i} style={{ padding: 7, verticalAlign: "top" }}>
        {awayPtsInQ === 0 && homePtsInQ === 0 ? "--" : awayPtsInQ}
      </td>
    );
    homeQuarters.push(
      <td key={i} style={{ padding: 7, verticalAlign: "top" }}>
        {awayPtsInQ === 0 && homePtsInQ === 0 ? "--" : homePtsInQ}
      </td>
    );
  }

  return (
    <div
      style={{
        display: "inline-block",
        color: "rgba(255,255,255,.8) !important",
        textAlign: "right",
        paddingTop: 10,
      }}
    >
      <table>
        <thead>
          <tr>
            <th />
            {headers}
            <th style={{ padding: 7, verticalAlign: "top" }}>T</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: 7, verticalAlign: "top", fontWeight: 600 }}>
              {awayAbbr}
            </td>
            {awayQuarters}
            <td style={{ padding: 7, verticalAlign: "top", fontWeight: 600 }}>
              {awayPts === 0 && homePts === 0 ? "--" : awayPts}
            </td>
          </tr>
          <tr>
            <td style={{ padding: 7, verticalAlign: "top", fontWeight: 600 }}>
              {homeAbbr}
            </td>
            {homeQuarters}
            <td style={{ padding: 7, verticalAlign: "top", fontWeight: 600 }}>
              {homePts === 0 && awayPts === 0 ? "--" : homePts}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
