import React, { useMemo } from "react";

import { Table, createColumnHelper } from "../core/Table";
import { TableNote } from "../core/TableNote";
import { PlayerEstPer100Box } from "../../../shared/routers/PlayerRouter";
import {
  seasonString,
  decFormat,
  dec100Format,
  decFormat2,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerStatColorDomains } from "../../constants/ColorDomains";
import { LEAGUE_COLORS } from "../../constants/ColorConstants";
import { weightedAverage } from "../../util/Util";
import { TeamTableCell, TruncatedTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<PlayerEstPer100Box>();

export function PlayerEstimatedPer100BoxTable(props: {
  data: PlayerEstPer100Box[];
}) {
  const { data } = props;

  const colorDomain = PlayerStatColorDomains["per100Poss"];

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("league", {
        header: "League",
        cell: (info) => (
          <TruncatedTableCell>{info.getValue()}</TruncatedTableCell>
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("season", {
        header: "Season",
        cell: (info) => seasonString(info.getValue().toString()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("team", {
        header: "Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue() || undefined}
            id={info.row.original.teamId || undefined}
            ids={info.row.original.teamIds || undefined}
            season={info.row.original.season.toString()}
            league={info.row.original.league || undefined}
          />
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("age", {
        header: "Age",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("min", {
        header: "MPG",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["min"],
          group: g++,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("pts", {
        header: "PTS",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["pts"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ast", {
        header: "AST",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["ast"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("blk", {
        header: "BLK",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["blk"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("stl", {
        header: "STL",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["stl"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("tov", {
        header: "TOV",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["to"],
          group: g,
          highlights: Highlights.Min,
        },
      }),
      columnHelper.accessor("pf", {
        header: "PF",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["pf"],
          group: g++,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("orPct", {
        header: "OR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["orbpct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("drPct", {
        header: "DR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["drbpct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("tovPct", {
        header: "TOV%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["topct"],
          group: g++,
          highlights: Highlights.Min,
        },
      }),
      columnHelper.accessor("fg2Pct", {
        header: "2P%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["2pct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fg3Pct", {
        header: "3P%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["3pct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ftPct", {
        header: "FT%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["ftpct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fg3aPct", {
        header: "3PA%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["3papct"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fga2", {
        header: "2PA",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["2pa"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fga3", {
        header: "3PA",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["3pa"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("fta", {
        header: "FTA",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: colorDomain["fta"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ts", {
        header: "TS%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["ts"],
          group: g++,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ppp", {
        header: "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          colorDomain: colorDomain["ppp"],
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("usg", {
        header: "Usg",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          colorDomain: colorDomain["usg"],
          group: g++,
          highlights: Highlights.Max,
        },
      }),
    ];
  }, [colorDomain]);

  const processSelected = (selected: PlayerEstPer100Box[]) => {
    return [
      "", // League
      "", // Season
      "", // Team
      decFormat(weightedAverage("numPoss", "age", selected)),
      decFormat(weightedAverage("numPoss", "min", selected)),
      decFormat(weightedAverage("numPoss", "pts", selected)),
      decFormat(weightedAverage("numPoss", "ast", selected)),
      decFormat(weightedAverage("numPoss", "blk", selected)),
      decFormat(weightedAverage("numPoss", "stl", selected)),
      decFormat(weightedAverage("numPoss", "tov", selected)),
      decFormat(weightedAverage("numPoss", "pf", selected)),
      decFormat(weightedAverage("numPoss", "orPct", selected)),
      decFormat(weightedAverage("numPoss", "drPct", selected)),
      dec100Format(weightedAverage("numPoss", "tovPct", selected)),
      dec100Format(weightedAverage("fga2", "fg2Pct", selected)),
      dec100Format(weightedAverage("fga3", "fg3Pct", selected)),
      dec100Format(weightedAverage("fta", "ftPct", selected)),
      dec100Format(weightedAverage("fga3", "fg3aPct", selected)),
      decFormat(weightedAverage("numPoss", "fga2", selected)),
      decFormat(weightedAverage("numPoss", "fga3", selected)),
      decFormat(weightedAverage("numPoss", "fta", selected)),
      dec100Format(weightedAverage("numPoss", "ts", selected)),
      decFormat2(weightedAverage("numPoss", "ppp", selected)),
      dec100Format(weightedAverage("numPoss", "usg", selected)),
    ];
  };

  const rowColorMap: Record<number, { backgroundColor: string }> = {};

  let i = 0;
  for (const row of data) {
    const league =
      row.isYouthLeague && row.league !== "NCAA" ? "youth" : row.league;
    const leagueColor = league ? LEAGUE_COLORS[league] : LEAGUE_COLORS["other"];
    if (leagueColor && league && ["NCAA", "youth"].includes(league)) {
      rowColorMap[i] = {
        backgroundColor: leagueColor,
      };
    }
    i++;
  }

  return (
    <>
      <Table
        data={data.sort((a, b) => a.season - b.season)}
        columns={columns}
        autoWidth={true}
        showColorOnHover={true}
        processSelected={processSelected}
        rowColorMap={rowColorMap}
      />
      <TableNote note="Estimated stats per 100 possessions are calculated based on a weighted average of a player's most recent games, across all leagues." />
      <TableNote note="The listed team and league represent the team that they played the most time with." />
    </>
  );
}
