import React, { useMemo, useState } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Highlights } from "../../constants/AppConstants";
import {
  decFormat,
  dec100Format,
  atMostDecFormat,
  fracDecFormat,
  decFormat2,
  poundsFormat,
  footFormat,
  intFormat,
} from "../../util/Format";
import { MultiLeagueSeasonBox } from "../../../shared/routers/TeamRouter";
import { PlayerTableCell } from "../core/TableCell";
import { PlayerStatColorDomains } from "../../constants/ColorDomains";
import { sortNullsToEnd } from "../../util/Sort";

const columnHelper = createColumnHelper<MultiLeagueSeasonBox>();

function calcStatForType(
  data: MultiLeagueSeasonBox,
  stat: keyof MultiLeagueSeasonBox,
  type: "perGame" | "per100Poss"
): number | null {
  if (data[stat] === null) return null;
  else if (type === "perGame") {
    return (data[stat] as number) / data.gp;
  } else {
    return (100 * (data[stat] as number)) / data.EstPossPlayed;
  }
}

export function TeamMultiLeagueSeasonBox(props: {
  data: MultiLeagueSeasonBox[];
  type: "perGame" | "per100Poss";
}) {
  const { data, type } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const dataRow1 = data[0];

  const league = dataRow1 ? dataRow1.league : "";
  const season = dataRow1 ? dataRow1.season : 0;

  const columns = useMemo(() => {
    const needsFtaCorrection = league === "G-League" && season >= 2020;
    let g = 0;
    return [
      columnHelper.accessor("player", {
        header: "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("age", {
        header: "Age",
        cell: (info) => atMostDecFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("jerseyNumber", {
        header: "#",
        cell: (info) => intFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("Ht", {
        header: "Height",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("Wt", {
        header: "Weight",
        cell: (info) => poundsFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("wingSpan", {
        header: "WingSpan",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("eligibility", {
        header: "Class",
        cell: (info) => {
          const classMap: Record<string, string> = {
            Freshman: "Fr.",
            Sophomore: "So.",
            Junior: "Jr.",
            Senior: "Sr.",
          };
          const val = info.getValue();
          return val ? classMap[val] : "";
        },
        meta: { group: g },
      }),
      columnHelper.accessor("netImpactPred", {
        header: "Draft Model",
        cell: (info) => dec100Format(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "netImpactPred"),
        meta: { group: g, highlights: Highlights.Max },
      }),
      columnHelper.accessor("selectionProbability", {
        header: "NBA %",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor("gp", {
        header: "G",
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].gp,
        },
      }),
      columnHelper.accessor("gs", {
        header: "GS",
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].gs,
        },
      }),
      columnHelper.accessor((row) => row.min / row.gp, {
        id: "mpg",
        header: "MPG",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].min,
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "pts", type), {
        id: "pts",
        header: "PTS",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].pts,
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "roff", type), {
        id: "orb",
        header: "ORB",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].orb,
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "rtot", type), {
        id: "trb",
        header: "TRB",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].trb,
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "ast", type), {
        id: "ast",
        header: "AST",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].ast,
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "blk", type), {
        id: "blk",
        header: "BLK",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].blk,
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "stl", type), {
        id: "stl",
        header: "STL",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].stl,
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "turn", type), {
        id: "tov",
        header: "TOV",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: PlayerStatColorDomains[type].to,
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.pf === null
            ? null
            : type === "per100Poss"
            ? (100 * row.pf) / row.EstPossPlayed
            : (48 * row.pf) / row.min,
        {
          id: "pf",
          header: type === "per100Poss" ? "PF" : "PF/48",
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Min,
            colorDomain: PlayerStatColorDomains[type].pf,
          },
        }
      ),
      columnHelper.accessor("roffpct", {
        header: "OR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].orbpct,
        },
      }),
      columnHelper.accessor("rdefpct", {
        header: "DR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type].drbpct,
        },
      }),
      columnHelper.accessor("turnrate", {
        header: "TO%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: PlayerStatColorDomains[type].topct,
        },
      }),
      columnHelper.accessor((row) => (row.fg2a ? row.fg2m / row.fg2a : null), {
        id: "fg2pct",
        header: "2P%",
        cell: (info) =>
          fracDecFormat(45, {
            numerator: info.row.original.fg2m,
            denominator: info.row.original.fg2a,
          }),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["2pct"],
        },
      }),
      columnHelper.accessor((row) => (row.fg3a ? row.fg3m / row.fg3a : null), {
        id: "fg3pct",
        header: "3P%",
        cell: (info) =>
          fracDecFormat(45, {
            numerator: info.row.original.fg3m,
            denominator: info.row.original.fg3a,
          }),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["3pct"],
        },
      }),
      columnHelper.accessor((row) => (row.fta ? row.ftm / row.fta : null), {
        id: "ftpct",
        header: "FT%",
        cell: (info) =>
          fracDecFormat(20, {
            numerator: info.row.original.ftm,
            denominator: info.row.original.fta,
          }),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["ftpct"],
        },
      }),
      columnHelper.accessor((row) => (row.fga ? row.fg3a / row.fga : null), {
        id: "fg3apct",
        header: "3PA%",
        cell: (info) => {
          const val = info.getValue();
          if (val === null) return null;
          return decFormat(val * 100);
        },
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["3papct"],
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "fg2a", type), {
        id: "fg2a",
        header: "2PA",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["2pa"],
        },
      }),
      columnHelper.accessor((row) => calcStatForType(row, "fg3a", type), {
        id: "fg3a",
        header: "3PA",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["3pa"],
        },
      }),
      columnHelper.accessor(
        (row) =>
          type === "per100Poss"
            ? (100 * row.fta * (needsFtaCorrection ? 1.67 : 1)) /
              row.EstPossPlayed
            : (48 * row.fta * (needsFtaCorrection ? 1.67 : 1)) / row.min,
        {
          id: "ft",
          header: type === "per100Poss" ? "FT" : "FT/48",
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type].fta,
          },
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.fga === 0 && row.fta === 0
            ? null
            : row.pts /
              (2 *
                (row.fta * (needsFtaCorrection ? 1.67 : 1) * 0.44 + row.fga)),
        {
          id: "ts",
          header: "TS%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type].ts,
          },
        }
      ),
      columnHelper.accessor("ppp", {
        header: "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["ppp"],
        },
      }),
      columnHelper.accessor("usg", {
        header: "Usg",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["usg"],
        },
      }),
    ];
  }, [type, league, season]);

  const hiddenColumns = {
    gs: league === "NBA",
    eligibility: league === "NCAA",
  };

  return (
    <Table
      data={data}
      columns={columns}
      hiddenColumns={hiddenColumns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
