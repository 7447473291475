import React, { useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { PlayerMultiLeagueGameLogs } from "../../../shared/routers/PlayerRouter";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  decFormat,
  seasonString,
  minutesFormat,
  intFormat,
} from "../../util/Format";
import { groupBy } from "../../../shared/util/Collections";
import { Highlights } from "../../constants/AppConstants";
import { sum } from "../../util/Util";
import { TeamTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<PlayerMultiLeagueGameLogs>();

export function PlayerMultiLeagueGameLogsTable(props: {
  multiLeagueGameLogs: PlayerMultiLeagueGameLogs[];
}) {
  const { multiLeagueGameLogs } = props;
  const gameLogsBySeason = groupBy(multiLeagueGameLogs, (m) =>
    m.Season.toString()
  );
  const mostRecentSeason = Object.keys(gameLogsBySeason).sort((a, b) =>
    a > b ? -1 : 1
  )[0];
  const [season, setSeason] = useState(mostRecentSeason || "");
  const [sorting, setSorting] = useState<SortingState>();

  const dataForSeason = gameLogsBySeason[season] || [];

  const data = dataForSeason.length
    ? dataForSeason.sort((a, b) => moment(b.gameDate).diff(moment(a.gameDate)))
    : [];

  const columns = useMemo(
    () => [
      columnHelper.accessor("league", {
        header: () => "League",
        cell: (info) => info.getValue(),
        meta: { group: 0, textAlign: "left" },
      }),
      columnHelper.accessor("team", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            ids={info.row.original.teamIdsId || undefined}
            id={info.row.original.teamId || undefined}
            name={info.getValue()}
            league={info.row.original.league
              .toLocaleLowerCase()
              .replaceAll(" ", "_")}
            season={info.row.original.Season.toString()}
          />
        ),
        meta: { group: 1 },
      }),
      columnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => moment(info.getValue()).format("MM/DD/YYYY"),
        meta: { group: 2 },
      }),
      columnHelper.accessor("opponent", {
        header: () => "Opp",
        cell: (info) => (
          <TeamTableCell
            ids={info.row.original.opponentIdsId || undefined}
            id={info.row.original.opponentId || undefined}
            name={info.getValue()}
            league={
              info.row.original.opponentIdsId
                ? undefined
                : info.row.original.league.toLocaleLowerCase().replace(" ", "_")
            }
            season={
              info.row.original.Season && !info.row.original.opponentIdsId
                ? info.row.original.Season.toString()
                : undefined
            }
          />
        ),
        meta: { group: 3 },
      }),
      columnHelper.accessor("wonGame", {
        header: () => "W/L",
        cell: (info) => {
          const d = info.row.original;
          let wonLossStr;
          if (d.wonGame == null) {
            wonLossStr = null;
          } else if (d.wonGame === 1) {
            wonLossStr = "W";
          } else {
            wonLossStr = "L";
          }

          if (d.leagueKey === "nba" || d.leagueKey === "nba_preseason") {
            return (
              <Link title={"Go to game page"} to={`/game/${d.gameID}`}>
                {wonLossStr}
              </Link>
            );
          }

          return (
            <Link
              title={"Go to game page"}
              to={`/game/ml/${d.leagueKey}/${d.gameID}`}
            >
              {wonLossStr}
            </Link>
          );
        },
        meta: { group: 4 },
      }),
      columnHelper.accessor("minutes", {
        header: () => "Min",
        cell: (info) =>
          info.row.original.playerMinutesExist ? (
            <Link
              to={`/player-minutes/${info.row.original.gameID}/${info.row.original.celticsID}`}
            >
              {minutesFormat(info.getValue())}
            </Link>
          ) : (
            minutesFormat(info.getValue())
          ),
        meta: { highlights: Highlights.Max, group: 5 },
      }),
      columnHelper.accessor("PTS", {
        header: () => "PTS",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 6 },
      }),
      columnHelper.accessor("rtot", {
        header: () => "REB",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 6 },
      }),
      columnHelper.accessor("AST", {
        header: () => "AST",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 6 },
      }),
      columnHelper.accessor(
        (row) =>
          row.fg2m === null || row.fg2a === null
            ? null
            : row.fg2m / (row.fg2a || 1),
        {
          id: "fg2",
          header: () => "2PM/A",
          cell: (info) => (
            <span>{`${info.row.original.fg2m}/${info.row.original.fg2a}`}</span>
          ),
          meta: { highlights: Highlights.Max, group: 7 },
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.fg3m === null || row.fg3a === null
            ? null
            : row.fg3m / (row.fg3a || 1),
        {
          id: "fg3",
          header: () => "3PM/A",
          cell: (info) => (
            <span>{`${info.row.original.fg3m}/${info.row.original.fg3a}`}</span>
          ),
          meta: { highlights: Highlights.Max, group: 7 },
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.FTM === null || row.FTA === null
            ? null
            : row.FTM / (row.FTA || 1),
        {
          id: "ft",
          header: () => "FTM/A",
          cell: (info) => (
            <span>{`${info.row.original.FTM}/${info.row.original.FTA}`}</span>
          ),
          meta: { highlights: Highlights.Max, group: 7 },
        }
      ),
      columnHelper.accessor("roff", {
        header: () => "OR",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 8 },
      }),
      columnHelper.accessor("rdef", {
        header: () => "DR",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 8 },
      }),
      columnHelper.accessor("BLK", {
        header: () => "BLK",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 9 },
      }),
      columnHelper.accessor("STL", {
        header: () => "STL",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 9 },
      }),
      columnHelper.accessor("turn", {
        header: () => "TOV",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 10 },
      }),
      columnHelper.accessor("PF", {
        header: () => "PF",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 10 },
      }),
      columnHelper.accessor("plusminus", {
        header: () => "+/-",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: 11 },
      }),
    ],
    []
  );

  const processSelected = (data: PlayerMultiLeagueGameLogs[]) => {
    return [
      "",
      "",
      "",
      "",
      "",
      minutesFormat(sum("minutes", data) / data.length),
      decFormat(sum("PTS", data) / data.length),
      decFormat(sum("rtot", data) / data.length),
      decFormat(sum("AST", data) / data.length),
      `${sum("fg2m", data)} / ${sum("fg2a", data)}`,
      `${sum("fg3m", data)} / ${sum("fg3a", data)}`,
      `${sum("FTM", data)} / ${sum("FTA", data)}`,
      decFormat(sum("roff", data) / data.length),
      decFormat(sum("rdef", data) / data.length),
      decFormat(sum("BLK", data) / data.length),
      decFormat(sum("STL", data) / data.length),
      decFormat(sum("turn", data) / data.length),
      decFormat(sum("PF", data) / data.length),
      sum("plusminus", data),
    ];
  };

  // These stats don't exist for many leagues so for guys that have never played
  // in the NBA just hide all these columns so we don't just see a bunch of
  // empty columns.
  const hasPlusMinusData = data.some((d) => d.plusminus !== null);

  const hiddenColumns = {
    plusminus: hasPlusMinusData,
  };

  return (
    <div>
      <Form.Label>Season</Form.Label>
      <Form.Select
        value={season}
        style={{ width: "auto", display: "inline-block", marginLeft: 10 }}
        onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
          setSeason(evt.target.value)
        }
      >
        {Object.keys(gameLogsBySeason)
          .reverse()
          .map((season) => (
            <option key={season} value={season}>
              {seasonString(season)}
            </option>
          ))}
      </Form.Select>
      {/* TODO(chrisbu): Merge this with PlayerGameBoxScores. */}
      <Table
        key={season}
        hiddenColumns={hiddenColumns}
        data={data}
        columns={columns}
        autoWidth={true}
        sorting={sorting}
        setSorting={setSorting}
        processSelected={processSelected}
      />
    </div>
  );
}
