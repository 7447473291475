import React from "react";
import { Col, Row } from "react-bootstrap";
import { createStyles, makeStyles } from "@material-ui/styles";
import moment from "moment";

import { Shot } from "../../../shared/routers/ShotRouter";
import { StatComparisonTableBars } from "../table/StatComparisonTableBars";
import {
  decFormat,
  decFormat2,
  makePlusMinus,
  intFormat,
  pctFormat,
  TABLE_EMPTY_VALUE_STR,
} from "../../util/Format";
import { ShotModelLink } from "../core/ShotModelLink";

const useStyles = makeStyles(() =>
  createStyles({
    primaryHoverText: {
      fontWeight: 600,
      lineHeight: 1,
      marginBottom: 2,
    },
    secondaryHoverText: {
      fontWeight: 100,
    },
  })
);

export function GameTeamShootingLuck(props: {
  data: Shot[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  fromDate: string;
  toDate: string;
}) {
  const classes = useStyles();
  const { data, home, away, fromDate, toDate } = props;

  const homeTeamId = home.teamid;
  const homeTeamAbbreviation = home.teamabbreviation;
  const awayTeamId = away.teamid;
  const awayTeamAbbreviation = away.teamabbreviation;
  const pointsDomain = [0, 140];

  function compareColorTeam(value: number, compare: number, key: string) {
    const team = key === "home" ? homeTeamId : awayTeamId;
    const otherTeam = key === "home" ? awayTeamId : homeTeamId;
    if (value >= compare) {
      return team.toString();
    } else {
      return otherTeam.toString();
    }
  }

  function compareFormat(compare: number, value: number) {
    return (
      <div>
        <div className={classes.primaryHoverText}>
          Actual Points:{" "}
          <span className={classes.secondaryHoverText}>
            {Math.round(value)}
          </span>
        </div>
        <div className={classes.primaryHoverText}>
          Expected Points:{" "}
          <span className={classes.secondaryHoverText}>
            {Math.round(compare)}
          </span>
        </div>
      </div>
    );
  }

  const homeShots = data.filter((s) => s.oteamId === homeTeamId);
  const homeThrees = homeShots.filter((s) => s.isThree === true);
  const homeLayups = homeShots.filter(
    (s) => s.generalShotType === "layup" && s.isThree === false
  );
  const homeTwoNonLayups = homeShots.filter(
    (s) => s.generalShotType !== "layup" && s.isThree === false
  );

  const awayShots = data.filter((s) => s.oteamId === awayTeamId);
  const awayThrees = awayShots.filter((s) => s.isThree === true);
  const awayLayups = awayShots.filter(
    (s) => s.generalShotType === "layup" && s.isThree === false
  );
  const awayTwoNonLayups = awayShots.filter(
    (s) => s.generalShotType !== "layup" && s.isThree === false
  );

  const fromDateStr = moment(fromDate).format("YYYY-MM-DD");
  const toDateStr = moment(toDate).format("YYYY-MM-DD");

  const groups = [
    [
      {
        label: (
          <ShotModelLink
            shotType="all"
            offTeams={[homeTeamId.toString(), awayTeamId.toString()]}
            groupBy={["oteam"]}
            gameDate={[fromDateStr, toDateStr]}
          >
            All Shots
          </ShotModelLink>
        ),
        format: decFormat,
        domain: pointsDomain,
        values: {
          home: homeShots.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayShots.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeShots.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayShots.reduce((prev, cur) => prev + cur.epps, 0),
        },
        compareColorTeam,
        compareFormat: {
          home: compareFormat,
          away: compareFormat,
        },
        additionalColumns: {
          PPS: {
            format: decFormat2,
            values: {
              home: homeShots.length
                ? homeShots.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeShots.length
                : 0,
              away: awayShots.length
                ? awayShots.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayShots.length
                : 0,
            },
          },
          ePPSLg: {
            format: decFormat2,
            values: {
              home: homeShots.length
                ? homeShots.reduce((prev, cur) => prev + cur.eppsLeague, 0) /
                  homeShots.length
                : 0,
              away: awayShots.length
                ? awayShots.reduce((prev, cur) => prev + cur.eppsLeague, 0) /
                  awayShots.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeShots.length
                ? homeShots.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeShots.length
                : 0,
              away: awayShots.length
                ? awayShots.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayShots.length
                : 0,
            },
          },
          numShots: {
            format: intFormat,
            values: {
              home: homeShots.length,
              away: awayShots.length,
            },
          },
          pctShots: {
            format: () => "",
            values: {
              home: null,
              away: null,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeShots.reduce((prev, cur) => prev + cur.pps, 0) -
                homeShots.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayShots.reduce((prev, cur) => prev + cur.pps, 0) -
                awayShots.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
        },
      },
      {
        label: (
          <ShotModelLink
            shotType="layup"
            offTeams={[homeTeamId.toString(), awayTeamId.toString()]}
            groupBy={["oteam"]}
            gameDate={[fromDateStr, toDateStr]}
          >
            2PA - Layups
          </ShotModelLink>
        ),
        format: decFormat2,
        domain: pointsDomain,
        values: {
          home: homeLayups.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayLayups.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeLayups.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayLayups.reduce((prev, cur) => prev + cur.epps, 0),
        },
        compareColorTeam,
        compareFormat: {
          home: compareFormat,
          away: compareFormat,
        },
        additionalColumns: {
          PPS: {
            format: decFormat2,
            values: {
              home: homeLayups.length
                ? homeLayups.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeLayups.length
                : 0,
              away: awayLayups.length
                ? awayLayups.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayLayups.length
                : 0,
            },
          },
          ePPSLg: {
            format: decFormat2,
            values: {
              home: homeLayups.length
                ? homeLayups.reduce((prev, cur) => prev + cur.eppsLeague, 0) /
                  homeLayups.length
                : 0,
              away: awayLayups.length
                ? awayLayups.reduce((prev, cur) => prev + cur.eppsLeague, 0) /
                  awayLayups.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeLayups.length
                ? homeLayups.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeLayups.length
                : 0,
              away: awayLayups.length
                ? awayLayups.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayLayups.length
                : 0,
            },
          },
          numShots: {
            format: intFormat,
            values: {
              home: homeLayups.length,
              away: awayLayups.length,
            },
          },
          pctShots: {
            format: (val: number | null) =>
              val ? `(${pctFormat(val)})` : TABLE_EMPTY_VALUE_STR,
            values: {
              home: homeLayups.length / homeShots.length,
              away: awayLayups.length / awayShots.length,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeLayups.reduce((prev, cur) => prev + cur.pps, 0) -
                homeLayups.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayLayups.reduce((prev, cur) => prev + cur.pps, 0) -
                awayLayups.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
        },
      },
      {
        label: (
          <ShotModelLink
            shotType="nl2"
            offTeams={[homeTeamId.toString(), awayTeamId.toString()]}
            groupBy={["oteam"]}
            gameDate={[fromDateStr, toDateStr]}
          >
            2PA - Non-Layups
          </ShotModelLink>
        ),
        format: decFormat2,
        domain: pointsDomain,
        values: {
          home: homeTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0),
        },
        compareColorTeam,
        compareFormat: {
          home: compareFormat,
          away: compareFormat,
        },
        additionalColumns: {
          PPS: {
            format: decFormat2,
            values: {
              home: homeTwoNonLayups.length
                ? homeTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeTwoNonLayups.length
                : 0,
              away: awayTwoNonLayups.length
                ? awayTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayTwoNonLayups.length
                : 0,
            },
          },
          ePPSLg: {
            format: decFormat2,
            values: {
              home: homeTwoNonLayups.length
                ? homeTwoNonLayups.reduce(
                    (prev, cur) => prev + cur.eppsLeague,
                    0
                  ) / homeTwoNonLayups.length
                : 0,
              away: awayTwoNonLayups.length
                ? awayTwoNonLayups.reduce(
                    (prev, cur) => prev + cur.eppsLeague,
                    0
                  ) / awayTwoNonLayups.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeTwoNonLayups.length
                ? homeTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeTwoNonLayups.length
                : 0,
              away: awayTwoNonLayups.length
                ? awayTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayTwoNonLayups.length
                : 0,
            },
          },
          numShots: {
            format: intFormat,
            values: {
              home: homeTwoNonLayups.length,
              away: awayTwoNonLayups.length,
            },
          },
          pctShots: {
            format: (val: number | null) =>
              val ? `(${pctFormat(val)})` : TABLE_EMPTY_VALUE_STR,
            values: {
              home: homeTwoNonLayups.length / homeShots.length,
              away: awayTwoNonLayups.length / awayShots.length,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0) -
                homeTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0) -
                awayTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
        },
      },
      {
        label: (
          <ShotModelLink
            shotType="three"
            offTeams={[homeTeamId.toString(), awayTeamId.toString()]}
            groupBy={["oteam"]}
            gameDate={[fromDateStr, toDateStr]}
          >
            3PA
          </ShotModelLink>
        ),
        format: decFormat2,
        domain: pointsDomain,
        values: {
          home: homeThrees.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayThrees.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeThrees.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayThrees.reduce((prev, cur) => prev + cur.epps, 0),
        },
        compareColorTeam,
        compareFormat: {
          home: compareFormat,
          away: compareFormat,
        },
        additionalColumns: {
          PPS: {
            format: decFormat2,
            values: {
              home: homeThrees.length
                ? homeThrees.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeThrees.length
                : 0,
              away: awayThrees.length
                ? awayThrees.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayThrees.length
                : 0,
            },
          },
          ePPSLg: {
            format: decFormat2,
            values: {
              home: homeThrees.length
                ? homeThrees.reduce((prev, cur) => prev + cur.eppsLeague, 0) /
                  homeThrees.length
                : 0,
              away: awayThrees.length
                ? awayThrees.reduce((prev, cur) => prev + cur.eppsLeague, 0) /
                  awayThrees.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeThrees.length
                ? homeThrees.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeThrees.length
                : 0,
              away: awayThrees.length
                ? awayThrees.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayThrees.length
                : 0,
            },
          },
          numShots: {
            format: intFormat,
            values: {
              home: homeThrees.length,
              away: awayThrees.length,
            },
          },
          pctShots: {
            format: (val: number | null) =>
              val ? `(${pctFormat(val)})` : TABLE_EMPTY_VALUE_STR,
            values: {
              home: homeThrees.length / homeShots.length,
              away: awayThrees.length / awayShots.length,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeThrees.reduce((prev, cur) => prev + cur.pps, 0) -
                homeThrees.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayThrees.reduce((prev, cur) => prev + cur.pps, 0) -
                awayThrees.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
        },
      },
    ],
  ];

  const additionalColumns = {
    numShots: {
      width: 95,
      label: "# of Shots",
    },
    pctShots: {
      width: 25,
      label: "",
    },
    ePPSLg: {
      width: 75,
      label: "xPPS Lg",
    },
    ePPS: {
      width: 75,
      label: "xPPS",
    },
    PPS: {
      width: 75,
      label: "PPS",
    },
    differential: {
      width: 75,
      label: "Diff.",
    },
  };

  return (
    <Row>
      {groups.map((group, i) => {
        return (
          <Col key={i} md={12}>
            <StatComparisonTableBars
              fitExtent={false}
              labels={{
                home: homeTeamAbbreviation,
                away: awayTeamAbbreviation,
              }}
              data={group}
              additionalColumns={additionalColumns}
              teams={{ home: homeTeamId, away: awayTeamId }}
              groupColumnLabel={"Team"}
              valueColumnLabel={"Points"}
              hideValuesColumn={true}
              hideBars={true}
              columnWidths={[135, 50]}
            />
          </Col>
        );
      })}
    </Row>
  );
}
