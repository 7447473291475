import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { DetailsControl } from "../query/DetailsControl";
import { Table, createColumnHelper, SortingState } from "../core/Table";
import {
  dateFormat,
  decFormat,
  decFormat2,
  seasonString,
  plusMinusFormat2,
  pctFormat,
  plusMinusFormat,
  dec100Format,
  makePlusMinus,
} from "../../util/Format";
import { PossessionAggregate } from "../../../shared/routers/PossessionRouter";
import { VideoControl } from "../query/VideoControl";
import { Highlights } from "../../constants/AppConstants";
import { teamColorDomain } from "../../constants/ColorDomains";
import { TeamTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<PossessionAggregate>();

export function PossessionExplorerResultsTable(props: {
  sorting: SortingState;
  setSorting: (sorting: SortingState) => void;
  data: PossessionAggregate[];
  columns: string[];
  highlightIdx?: number;
  groupBy: string[];
  setDetails: (data: { poss: PossessionAggregate; idx: number }) => void;
  setVideo: (data: { poss: PossessionAggregate; offDef: string }) => void;
}) {
  const {
    sorting,
    setSorting,
    data,
    columns: selectedColumns,
    highlightIdx,
    groupBy,
    setDetails,
    setVideo,
  } = props;

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("season", {
        header: () => "Season",
        cell: (info) => {
          const val = info.getValue();
          if (val) {
            return seasonString(val.toString());
          }
          return null;
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("lineup", {
        header: () => "On/Off",
        cell: (info) =>
          info.getValue() ? "Selected Lineup" : "Everything Else",
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("team", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.teamId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("lineup5ManNames", {
        header: () => "5 Man Lineup",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("numBigs", {
        header: () => "# Bigs",
        cell: (info) => info.getValue(),
        meta: { group: g++ },
      }),
      columnHelper.accessor("oppTeam", {
        header: () => "Opponent",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.oppTeamId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("isPlayoff", {
        header: () => "Game Type",
        cell: (info) => (info.getValue() ? "Playoffs" : "Regular Season"),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => {
          const val = info.getValue();
          return val
            ? dateFormat(new Date(val.toString().substring(0, 10)))
            : "";
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("gameString", {
        header: () => "Game",
        cell: (info) => (
          <Link to={`/game/${info.row.original.gameId}`}>
            {info.getValue()}
          </Link>
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("period", {
        header: () => "Period",
        meta: { group: g++ },
      }),
      columnHelper.accessor("zone", {
        header: () => "Zone",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("numPos", {
        header: () => "O Poss",
        cell: (info) => info.getValue().toLocaleString(),
        meta: { group: g, highlights: Highlights.Max },
      }),
      columnHelper.accessor("numPosDef", {
        header: () => "D Poss",
        cell: (info) => info.getValue().toLocaleString(),
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor(
        (row) =>
          row.min === null || row.minDef === null
            ? null
            : (row.numPos + row.numPosDef) / ((row.min + row.minDef) / 24),
        {
          id: "pace",
          header: () => "Pace",
          cell: (info) => decFormat(info.getValue()),
          meta: { group: g++, highlights: Highlights.Max },
        }
      ),
      columnHelper.accessor((row) => row.pts - row.ptsDef, {
        id: "plusMinus",
        header: () => "+/-",
        cell: (info) => plusMinusFormat(info.getValue()),
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor("xppp", {
        header: () => "Off xPPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: teamColorDomain.xppOff,
        },
      }),
      columnHelper.accessor("xpppDef", {
        header: () => "Def xPPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: teamColorDomain.xppDef,
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.xppp === null || row.xpppDef === null
            ? null
            : row.xppp - row.xpppDef,

        {
          id: "netxPPP",
          header: () => "Net",
          cell: (info) => plusMinusFormat2(info.getValue()),
          meta: {
            group: g++,
            colorDomain: teamColorDomain.xppNet,
            heatmap: true,
            highlights: Highlights.Max,
          },
        }
      ),
      columnHelper.accessor("predppp", {
        header: () => "Pred. PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: teamColorDomain.predppp,
        },
      }),
      columnHelper.accessor("predpppDef", {
        header: () => "Pred. Def PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: teamColorDomain.predpppDef,
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.predppp === null || row.predpppDef === null
            ? null
            : row.predppp - row.predpppDef,

        {
          id: "predpppNet",
          header: () => "Net",
          cell: (info) => plusMinusFormat2(info.getValue()),
          meta: {
            group: g++,
            colorDomain: teamColorDomain.predpppNet,
            highlights: Highlights.Max,
          },
        }
      ),
      columnHelper.accessor("ppp", {
        header: () => "Off PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: teamColorDomain.pppOff,
        },
      }),
      columnHelper.accessor("pppDef", {
        header: () => "Def PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: teamColorDomain.pppDef,
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.ppp === null || row.pppDef === null ? null : row.ppp - row.pppDef,

        {
          id: "netPPP",
          header: () => "Net",
          cell: (info) => plusMinusFormat2(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: teamColorDomain.pppNet,
          },
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.ppp === null ||
          row.pppDef === null ||
          row.xppp === null ||
          row.xpppDef === null
            ? null
            : row.ppp - row.pppDef - (row.xppp - row.xpppDef),
        {
          id: "delta",
          header: () => "∆",
          cell: (info) => plusMinusFormat2(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: teamColorDomain.delta,
            neutralColorScheme: true,
          },
        }
      ),
      columnHelper.group({
        id: "off",
        meta: { group: g },
        header: "Off",
        columns: [
          columnHelper.accessor("oppImpact", {
            header: () => "Opp Impact",
            cell: (info) => makePlusMinus(dec100Format)(5 * info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [-0.004, 0.0045],
            },
          }),
          columnHelper.accessor("xpps", {
            header: () => "xPPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.xppsOff,
            },
          }),
          columnHelper.accessor("pps", {
            header: () => "PPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.ppsOff,
            },
          }),
          columnHelper.accessor("shotsPerPoss", {
            header: () => "Shots/Poss",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.shotsPerPoss,
            },
          }),
          columnHelper.accessor("crashersPerShot", {
            header: () => "Crashers/Shot",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.crashersPerShots,
            },
          }),
          columnHelper.accessor("interiorCrashersPerShot", {
            header: () => "Int. Crashers/Shot",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.crashersPerShotInt,
            },
          }),
          columnHelper.accessor("perimCrashersPerShot", {
            header: () => "Per. Crashers/Shot",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.crashersPerShotPer,
            },
          }),
          columnHelper.accessor("layupPct", {
            header: () => "% Layup",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctLayup,
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("nl2Pct", {
            header: () => "% Non-Layup 2",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctNl2,
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("threePct", {
            header: () => "% 3",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctThree,
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("transitionPct", {
            header: () => "Transition%",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctTran,
            },
          }),
          columnHelper.accessor("toPct", {
            header: () => "TO%",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.pctTov,
            },
          }),
          columnHelper.accessor("xToPct", {
            header: () => "xTO%",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.pctTov,
            },
          }),
          columnHelper.accessor("orbPct", {
            header: () => "ORB %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctOrb,
            },
          }),
          columnHelper.accessor("xOrbPct", {
            header: () => "xORB %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctOrb,
            },
          }),
          columnHelper.accessor("zonePct", {
            header: () => "Zone%",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctZone,
              neutralColorScheme: true,
            },
          }),
          columnHelper.display({
            id: "videoOff",
            header: () => "Video",
            cell: (info) =>
              info.row.original.numPos > 0 && (
                <VideoControl
                  data={info.row.original}
                  onVideo={() =>
                    setVideo({ poss: info.row.original, offDef: "Off" })
                  }
                />
              ),
            meta: { group: g++ },
          }),
        ],
      }),
      columnHelper.group({
        id: "def",
        meta: { group: g },
        header: "Def",
        columns: [
          columnHelper.accessor("oppImpactDef", {
            header: () => "Opp Impact",
            cell: (info) => makePlusMinus(dec100Format)(5 * info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [-0.004, 0.0045],
            },
          }),
          columnHelper.accessor("xppsDef", {
            header: () => "xPPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.xppsDef,
            },
          }),
          columnHelper.accessor("ppsDef", {
            header: () => "PPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.ppsDef,
            },
          }),
          columnHelper.accessor("shotsPerPossDef", {
            header: () => "Shots/Poss",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.shotsPerPossDef,
            },
          }),
          columnHelper.accessor("crashersPerShotDef", {
            header: () => "Crashers/Shot",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.crashersPerShotsDef,
            },
          }),
          columnHelper.accessor("interiorCrashersPerShotDef", {
            header: () => "Int. Crashers/Shot",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.crashersPerShotIntDef,
            },
          }),
          columnHelper.accessor("perimCrashersPerShotDef", {
            header: () => "Per. Crashers/Shot",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.crashersPerShotPerDef,
            },
          }),
          columnHelper.accessor("layupPctDef", {
            header: () => "% Layup",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctLayupDef,
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("nl2PctDef", {
            header: () => "% Non-Layup 2",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctNl2Def,
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("threePctDef", {
            header: () => "% 3",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctThreeDef,
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("transitionPctDef", {
            header: () => "Transition%",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.pctTranDef,
            },
          }),
          columnHelper.accessor("toPctDef", {
            header: () => "TO%",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctTovDef,
            },
          }),
          columnHelper.accessor("xToPctDef", {
            header: () => "xTO%",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctTovDef,
            },
          }),
          columnHelper.accessor("orbPctDef", {
            header: () => "ORB %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.pctOrbDef,
            },
          }),
          columnHelper.accessor("xOrbPctDef", {
            header: () => "xORB %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: teamColorDomain.pctOrbDef,
            },
          }),
          columnHelper.accessor("zonePctDef", {
            header: () => "Zone%",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: teamColorDomain.pctZoneDef,
              neutralColorScheme: true,
            },
          }),
          columnHelper.display({
            id: "videoDef",
            header: () => "Video",
            cell: (info) =>
              info.row.original.numPos > 0 && (
                <VideoControl
                  data={info.row.original}
                  onVideo={() =>
                    setVideo({ poss: info.row.original, offDef: "Def" })
                  }
                />
              ),
            meta: { group: g++ },
          }),
        ],
      }),
      columnHelper.display({
        id: "details",
        header: () => "Details",
        cell: (info) => (
          <DetailsControl
            id={""}
            data={info.row.original}
            onDetails={(i, d) => setDetails({ poss: d, idx: info.row.index })}
          />
        ),
        meta: { group: g++ },
      }),
    ];
  }, [setDetails, setVideo]);

  const hiddenColumns = {
    season: groupBy.includes("season"),
    lineup: groupBy.includes("lineup"),
    lineup5ManNames: groupBy.includes("lineup5Man"),
    numBigs: groupBy.includes("numBigs"),
    team: groupBy.includes("team"),
    oppTeam: groupBy.includes("oppTeam"),
    isPlayoff: groupBy.includes("isPlayoff"),
    gameDate: groupBy.includes("gameString"),
    gameString: groupBy.includes("gameString"),
    period: groupBy.includes("period"),
    zone: groupBy.includes("zone"),
    // Selectable Columns
    numPos: selectedColumns.includes("numPos"),
    numPosDef: selectedColumns.includes("numPos"),
    pace: selectedColumns.includes("pace"),
    plusMinus: selectedColumns.includes("plusMinus"),
    oppImpact: selectedColumns.includes("oppImpact"),
    oppImpactDef: selectedColumns.includes("oppImpact"),
    xppp: selectedColumns.includes("xppp"),
    xpppDef: selectedColumns.includes("xppp"),
    netxPPP: selectedColumns.includes("xppp"),
    xpps: selectedColumns.includes("xpps"),
    xppsDef: selectedColumns.includes("xpps"),
    predppp: selectedColumns.includes("predppp"),
    predpppDef: selectedColumns.includes("predppp"),
    predpppNet: selectedColumns.includes("predppp"),
    ppp: selectedColumns.includes("ppp"),
    pppDef: selectedColumns.includes("ppp"),
    netPPP: selectedColumns.includes("ppp"),
    pps: selectedColumns.includes("pps"),
    ppsDef: selectedColumns.includes("pps"),
    delta: selectedColumns.includes("delta"),
    shotsPerPoss: selectedColumns.includes("shotsPerPoss"),
    shotsPerPossDef: selectedColumns.includes("shotsPerPoss"),
    crashersPerShot: selectedColumns.includes("crashersPerShot"),
    crashersPerShotDef: selectedColumns.includes("crashersPerShot"),
    interiorCrashersPerShot: selectedColumns.includes(
      "interiorCrashersPerShot"
    ),
    interiorCrashersPerShotDef: selectedColumns.includes(
      "interiorCrashersPerShot"
    ),
    perimCrashersPerShot: selectedColumns.includes("perimCrashersPerShot"),
    perimCrashersPerShotDef: selectedColumns.includes("perimCrashersPerShot"),
    layupPct: selectedColumns.includes("layupPct"),
    layupPctDef: selectedColumns.includes("layupPct"),
    threePct: selectedColumns.includes("threePct"),
    threePctDef: selectedColumns.includes("threePct"),
    nl2Pct: selectedColumns.includes("nl2Pct"),
    nl2PctDef: selectedColumns.includes("nl2Pct"),
    zonePct: selectedColumns.includes("zonePct"),
    zonePctDef: selectedColumns.includes("zonePct"),
    transitionPct: selectedColumns.includes("transitionPct"),
    transitionPctDef: selectedColumns.includes("transitionPct"),
    toPct: selectedColumns.includes("toPct"),
    toPctDef: selectedColumns.includes("toPct"),
    xToPct: selectedColumns.includes("xToPct"),
    xToPctDef: selectedColumns.includes("xToPct"),
    orbPct: selectedColumns.includes("orbPct"),
    orbPctDef: selectedColumns.includes("orbPct"),
    xOrbPct: selectedColumns.includes("xOrbPct"),
    xOrbPctDef: selectedColumns.includes("xOrbPct"),
  };

  return (
    <Table
      data={data}
      columns={columns}
      hiddenColumns={hiddenColumns}
      autoWidth={true}
      virtualScroll={true}
      showColorOnHover={true}
      sorting={sorting}
      setSorting={setSorting}
      rowColorMap={
        highlightIdx !== undefined
          ? { [highlightIdx]: { backgroundColor: "#ffffe0" } }
          : undefined
      }
    />
  );
}
