import React, { useState, useMemo } from "react";
import moment from "moment";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { Alert } from "react-bootstrap";

import { Table, createColumnHelper, SortingState } from "../core/Table";
import {
  Possession,
  PossessionFilters,
} from "../../../shared/routers/PossessionRouter";
import { LineChart, Line } from "../chart/LineChart";
import { decFormat2 } from "../../util/Format";
import { lineChartColors } from "../../constants/ColorConstants";
import { Col, Row } from "react-bootstrap";
import { FilterChips } from "../query/FilterChips";
import { Restrict } from "../core/Restrict";

// The most poss we've ever seen in a season by a single team is 10,000
// off and def possessions so to make sure you can always visualize a
// full season's worth in the chart we set this at 25k.
export const RESULT_LIMIT = 25_000;

const RESULT_LIMIT_MSG = `Your query produced a large return set. To improve
performance we've limited the return data to ${RESULT_LIMIT.toLocaleString()}
rows.`;

const Y_DOMAIN_OPT_VALS = [
  0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5,
  1.6, 1.7, 1.8, 1.9, 2,
];

const columnHelper = createColumnHelper<Possession>();

export function PossessionExplorerDetails(props: {
  data: Possession[];
  filters: PossessionFilters;
  deletableFilters: string[];
  onDeleteFilter: (filter: string) => void;
  offDef: string;
  setOffDef: (v: string) => void;
  expectedActual: string;
  setExpectedActual: (v: string) => void;
  yMin: string;
  setYMin: (v: string) => void;
  yMax: string;
  setYMax: (v: string) => void;
}) {
  const {
    data,
    filters,
    deletableFilters,
    onDeleteFilter,
    offDef,
    setOffDef,
    expectedActual,
    setExpectedActual,
    yMin,
    setYMin,
    yMax,
    setYMax,
  } = props;

  const lines: Line[] = useMemo(() => {
    const xpppOffPoints: { x: number; y: number; runningTotal: number }[] = [];
    const xpppDefPoints: { x: number; y: number; runningTotal: number }[] = [];
    const pppOffPoints: { x: number; y: number; runningTotal: number }[] = [];
    const pppDefPoints: { x: number; y: number; runningTotal: number }[] = [];
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      if (d && d.offDef === "Off") {
        const lastxpppOffPoints = xpppOffPoints[xpppOffPoints.length - 1];

        const prevXpppVal = lastxpppOffPoints
          ? lastxpppOffPoints.runningTotal
          : 0;
        xpppOffPoints.push({
          x: xpppOffPoints.length,
          y: (d.xPts + prevXpppVal) / (xpppOffPoints.length + 1),
          runningTotal: d.xPts + prevXpppVal,
        });

        const lastpppOffPoints = pppOffPoints[pppOffPoints.length - 1];
        const prevpppVal = lastpppOffPoints ? lastpppOffPoints.runningTotal : 0;
        pppOffPoints.push({
          x: pppOffPoints.length,
          y: (d.pts + prevpppVal) / (pppOffPoints.length + 1),
          runningTotal: d.pts + prevpppVal,
        });
      } else if (d && d.offDef === "Def") {
        const lastxpppDefPoints = xpppDefPoints[xpppDefPoints.length - 1];

        const prevXpppVal = lastxpppDefPoints
          ? lastxpppDefPoints.runningTotal
          : 0;
        xpppDefPoints.push({
          x: xpppDefPoints.length,
          y: (d.xPts + prevXpppVal) / (xpppDefPoints.length + 1),
          runningTotal: d.xPts + prevXpppVal,
        });

        const lastpppDefPoints = pppDefPoints[pppDefPoints.length - 1];
        const prevpppVal = lastpppDefPoints ? lastpppDefPoints.runningTotal : 0;
        pppDefPoints.push({
          x: pppDefPoints.length,
          y: (d.pts + prevpppVal) / (pppDefPoints.length + 1),
          runningTotal: d.pts + prevpppVal,
        });
      }
    }

    const xPpp = {
      label: "xPPP (Off)",
      color: lineChartColors.blue,
      segments: [xpppOffPoints],
    };

    const ppp = {
      label: "PPP (Off)",
      color: lineChartColors.turqoiuse,
      segments: [pppOffPoints],
    };

    const xPppDef = {
      label: "xPPP (Def)",
      color: lineChartColors.orange,
      segments: [xpppDefPoints],
    };

    const pppDef = {
      label: "PPP (Def)",
      color: lineChartColors.yellow,
      segments: [pppDefPoints],
    };

    if (offDef === "Both") {
      if (expectedActual === "Both") {
        return [xPpp, ppp, xPppDef, pppDef];
      } else if (expectedActual === "xPPP") {
        return [xPpp, xPppDef];
      } else {
        return [ppp, pppDef];
      }
    } else if (offDef === "Off") {
      if (expectedActual === "Both") {
        return [xPpp, ppp];
      } else if (expectedActual === "xPPP") {
        return [xPpp];
      } else {
        return [ppp];
      }
    } else {
      if (expectedActual === "Both") {
        return [xPppDef, pppDef];
      } else if (expectedActual === "xPPP") {
        return [xPppDef];
      } else {
        return [pppDef];
      }
    }
  }, [data, offDef, expectedActual]);

  const yDomainMin = parseFloat(yMin);
  const yDomainMax = parseFloat(yMax);

  const oData = data.filter((d) => d.offDef === "Off");
  const dData = data.filter((d) => d.offDef === "Def");

  return (
    <div>
      {data.length === RESULT_LIMIT && (
        <Alert variant="warning">{RESULT_LIMIT_MSG}</Alert>
      )}
      <Row style={{ marginBottom: 8 }}>
        <Col>
          <FilterChips
            filters={filters}
            deletableFilters={deletableFilters}
            onRemove={(k) => onDeleteFilter(k)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span style={{ marginRight: 8 }}>
            <b>Off/Def: </b>
            <select
              value={offDef}
              onChange={(e) => setOffDef(e.currentTarget.value)}
            >
              {["Both", "Off", "Def"].map((o) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </select>
          </span>
          <span style={{ marginRight: 8 }}>
            <b>xPPP/PPP: </b>
            <select
              value={expectedActual}
              onChange={(e) => setExpectedActual(e.currentTarget.value)}
            >
              {["Both", "xPPP", "PPP"].map((o) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </select>
          </span>
          <span>
            <b>Y Range: </b>
            <select
              value={yDomainMin}
              onChange={(e) => setYMin(e.currentTarget.value)}
            >
              {Y_DOMAIN_OPT_VALS.map((o) => (
                <option key={o} value={o.toString()}>
                  {decFormat2(o)}
                </option>
              ))}
            </select>

            {" to "}
            <select
              value={yDomainMax}
              onChange={(e) => setYMax(e.currentTarget.value)}
            >
              {Y_DOMAIN_OPT_VALS.map((o) => (
                <option key={o} value={o.toString()}>
                  {decFormat2(o)}
                </option>
              ))}
            </select>
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <ParentSize>
            {({ width }) => (
              <div style={{ position: "relative" }}>
                <LineChart
                  height={width / 2}
                  width={width}
                  lines={lines}
                  showLegend={true}
                  yDomain={[yDomainMin, yDomainMax]}
                  yTickFormat={(val) => decFormat2(val as number)}
                  numXTicks={4}
                  referenceLines={[{ value: 1.13 }]}
                  tooltip={(d) => {
                    const data = d.data;
                    if (!data) return;

                    const firstData = data[0];

                    if (!firstData) return null;

                    return (
                      <div>
                        <div>
                          {/* Add 1 so that the first one shows up as poss #1, not #0 */}
                          <b># Poss: {(firstData.x + 1).toLocaleString()}</b>
                        </div>
                        {data.map((d, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              gap: 8,
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                background: d.color,
                                width: 10,
                                height: 10,
                              }}
                            ></div>
                            {decFormat2(d.y)} {d.label}
                          </div>
                        ))}
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </ParentSize>
        </Col>
      </Row>
      <Restrict roles={["admin"]}>
        <Row>
          <Col md={12}>
            <>
              <b>[BIA ADMIN DEBUG ONLY]</b>
              <PossessionExplorerResultsTable data={oData} />
              <PossessionExplorerResultsTable data={dData} />
            </>
          </Col>
        </Row>
      </Restrict>
    </div>
  );
}

function PossessionExplorerResultsTable(props: { data: Possession[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => moment(info.getValue()).format("MM/DD/YYYY"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("offTeam", {
        header: () => "OffTeam",
        meta: { group: g++ },
      }),
      columnHelper.accessor("defTeam", {
        header: () => "DefTeam",
        meta: { group: g++ },
      }),
      columnHelper.accessor(
        (row) => [
          row.offPlayer1,
          row.offPlayer2,
          row.offPlayer3,
          row.offPlayer4,
          row.offPlayer5,
        ],
        {
          id: "oLineup",
          header: () => "Offense",
          cell: (info) =>
            info
              .getValue()
              .map((p) => {
                const parts = p.split(" ");
                return parts[parts.length - 1];
              })
              .sort()
              .join(", "),
          meta: { group: g++ },
        }
      ),
      columnHelper.accessor(
        (row) => [
          row.defPlayer1,
          row.defPlayer2,
          row.defPlayer3,
          row.defPlayer4,
          row.defPlayer5,
        ],
        {
          id: "dLineup",
          header: () => "Defense",
          cell: (info) =>
            info
              .getValue()
              .map((p) => {
                const parts = p.split(" ");
                return parts[parts.length - 1];
              })
              .sort()
              .join(", "),
          meta: { group: g++ },
        }
      ),
      columnHelper.accessor("startType", {
        header: () => "Start",
        meta: { group: g++ },
      }),
      columnHelper.accessor("outcome", {
        header: () => "Outcome",
        meta: { group: g++ },
      }),
      columnHelper.accessor("orb", {
        header: () => "ORB",
        meta: { group: g++ },
      }),
      columnHelper.accessor("orbAvail", {
        header: () => "ORB Avail",
        meta: { group: g++ },
      }),
      columnHelper.accessor("isTransition", {
        header: () => "Transition",
        cell: (info) => (info.getValue() ? "Y" : "N"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("zone", {
        header: () => "Zone",
        cell: (info) => (info.getValue() ? "Y" : "N"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("xPts", {
        header: () => "xPts",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("predPts", {
        header: () => "Pred. Pts",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("pts", {
        header: () => "Pts",
        meta: { group: g++ },
      }),
    ];
  }, []);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      virtualScroll={true}
    />
  );
}
