import React from "react";
import { scaleLinear } from "@visx/scale";
import { generateDomain } from "../../util/Util";
import { winProbColorScheme } from "../../constants/ChartConstants";

export function WinProbBar(props: {
  winProb: number;
  baselineWinProb: number;
  chartWidth: number;
  baselineWinProbColor?: string;
}) {
  const { winProb, baselineWinProb, chartWidth } = props;

  const midY = 6;
  const strokeWidth = 6;

  // Only occupy 80% of the available width.
  const pctOfWidth = 0.8;

  const startX = (chartWidth * (1 - pctOfWidth)) / 2;
  const endX = chartWidth - startX;

  const baselineX = baselineWinProb * (chartWidth * pctOfWidth) + startX;
  const baselineColor = props.baselineWinProbColor || "white";

  const colorScale = scaleLinear<string>({
    range: winProbColorScheme,
    domain: generateDomain(0, 1, winProbColorScheme.length),
  });

  const color = colorScale(winProb);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: chartWidth }}>
      <polygon
        points={`${baselineX - 1} ${midY * 2}, ${baselineX} ${
          midY * 2 - strokeWidth / 2 + 1
        }, ${baselineX + 1} ${midY * 2}`}
        fill={baselineColor}
        stroke={baselineColor}
        strokeWidth={1.5}
      />
      <polygon
        points={`${baselineX - 1} 0, ${baselineX} ${
          midY - strokeWidth / 2 - 1
        }, ${baselineX + 1} 0`}
        fill={baselineColor}
        stroke={baselineColor}
        strokeWidth={1.5}
      />
      {/* The strokeWidth/2 adjustments are to make room for the rounded cap. */}
      <line
        x1={startX + strokeWidth / 2}
        y1={midY}
        x2={endX - strokeWidth / 2}
        y2={midY}
        strokeWidth={strokeWidth}
        stroke={"gray"}
        strokeLinecap="round"
      />
      <line
        x1={startX + strokeWidth / 2}
        y1={midY}
        x2={winProb * (chartWidth * pctOfWidth) + startX - strokeWidth / 2}
        y2={midY}
        strokeWidth={strokeWidth}
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}
