import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { CrashAttempt } from "../../../shared/routers/GameRouter";
import { CrashingViz } from "../crashing/CrashingViz";
import { CrashingTable } from "../crashing/CrashingTable";

export function LiveGameCrashingViz(props: {
  teams: { teamId: number; teamName: string }[];
  players: {
    playerId: number;
    playerName: string;
    eagleId: string;
    teamId: number;
    homeAway: string;
    ftPct: number;
  }[];
  crashAttempts: CrashAttempt[];
}) {
  const { teams, players, crashAttempts } = props;
  const firstTeam = teams[0];
  const [teamId, setTeamId] = useState<string>(
    firstTeam ? firstTeam.teamId.toString() : ""
  );
  const [playerId, setPlayerId] = useState<string>("");
  const [period, setPeriod] = useState(0);
  const [location, setLocation] = useState("All Locations");
  const [noLayups, setNoLayups] = useState(true);

  const playerOptions = players.filter((p) => p.teamId.toString() === teamId);

  const filteredcrashAttempts = crashAttempts.filter((c) => {
    if (playerId !== "" && c.playerId.toString() !== playerId) return false;
    if (noLayups && c.shotType === "layup") return false;
    if (teamId !== "" && c.teamId.toString() !== teamId) return false;
    if (location !== "All Locations" && c.location !== location) return false;
    if (period === 0) return true;
    if (period === 5) return c.period > 4;
    return c.period === period;
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        <select
          value={teamId}
          onChange={(evt) => {
            const newTeamId = evt.target.value;
            setTeamId(newTeamId);
            // Reset the player as well so we don't have a team/player mismatch.
            setPlayerId("");
          }}
        >
          {teams.map((t) => (
            <option value={t.teamId.toString()} key={t.teamId.toString()}>
              {t.teamName}
            </option>
          ))}
        </select>
        <select
          value={playerId}
          onChange={(e) => setPlayerId(e.currentTarget.value)}
        >
          <option value="">All Players</option>
          {playerOptions.map((p) => (
            <option key={p.eagleId} value={p.eagleId}>
              {p.playerName}
            </option>
          ))}
        </select>
        <select
          value={period}
          onChange={(evt) => setPeriod(parseInt(evt.target.value))}
        >
          {[
            { value: 0, label: "Full Game" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "OT" },
          ].map((po) => (
            <option value={po.value} key={po.value}>
              {po.label}
            </option>
          ))}
        </select>
        <select
          value={location}
          onChange={(evt) => setLocation(evt.target.value)}
        >
          {["All Locations", "Corner", "Inside the Arc", "Above the Break"].map(
            (l) => (
              <option value={l} key={l}>
                {l}
              </option>
            )
          )}
        </select>
        <Form.Check
          type="checkbox"
          checked={noLayups}
          label="Hide Layups"
          onChange={() => setNoLayups(!noLayups)}
        />
      </div>
      <CrashingViz
        data={filteredcrashAttempts}
        disableHover={playerId === ""}
      />
      <CrashingTable data={filteredcrashAttempts} showPredCols={false} />
    </div>
  );
}
