import React, { useState, useRef } from "react";
import {
  Col,
  Row,
  Overlay,
  Form,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { ParentSize } from "@visx/responsive";

import { TeamStats } from "../../../shared/routers/TeamRouter";
import {
  decFormat2,
  decFormat,
  dec100Format,
  makePlusMinus,
} from "../../util/Format";
import { getContrastingTeamColor } from "../../util/Colors";
import { Comet } from "../chart/Comet";
import { TableNote } from "../core/TableNote";
import { average } from "../../util/Util";

export function GameTeamStats(props: {
  home: { teamabbreviation: string; teamid: number };
  away: { teamabbreviation: string; teamid: number };
  homeStats: TeamStats;
  awayStats: TeamStats;
  leagueStats: TeamStats[];
  leagueGames: TeamStats[];
}) {
  const { home, away, homeStats, awayStats, leagueStats, leagueGames } = props;

  const [compareTeam, setCompareTeam] = useState<"home" | "away">("away");

  const homeTeamId = home.teamid;
  const awayTeamId = away.teamid;

  const homeLeagueTeamStats = leagueStats.find(
    (ls) => ls.teamId === homeTeamId
  );
  const awayLeagueTeamStats = leagueStats.find(
    (ls) => ls.teamId === awayTeamId
  );

  // Domains based on 5% and 95% percentiles across all games.
  const groups: {
    label: string;
    stat: keyof TeamStats;
    statDef: keyof TeamStats;
    format: (n: number | null) => string;
    lowerIsBetter?: boolean;
  }[][] = [
    [
      {
        label: "xPPP",
        stat: "xPPP",
        statDef: "xPPPOpp",
        format: decFormat2,
      },
      {
        label: "PPP",
        stat: "PPP",
        statDef: "PPPOpp",
        format: decFormat2,
      },
      {
        label: "xPPS",
        stat: "xPPS",
        statDef: "xPPSOpp",
        format: decFormat2,
      },
      {
        label: "PPS",
        stat: "PPS",
        statDef: "PPSOpp",
        format: decFormat2,
      },
      {
        label: "xPPS Lg",
        stat: "xPPSLg",
        statDef: "xPPSLgOpp",
        format: decFormat2,
      },
      {
        label: "xOR%",
        stat: "xOrPct",
        statDef: "xOrPctOpp",
        format: decFormat,
      },
      {
        label: "OR%",
        stat: "orPct",
        statDef: "orPctOpp",
        format: decFormat,
      },
      {
        label: "xTOV%",
        stat: "xTovPct",
        statDef: "xTovPctOpp",
        format: decFormat,
        lowerIsBetter: true,
      },
      {
        label: "TOV%",
        stat: "tovPct",
        statDef: "tovPctOpp",
        format: decFormat,
        lowerIsBetter: true,
      },
    ],
    [
      {
        label: "Layup%",
        stat: "layupPct",
        statDef: "layupPctOpp",
        format: decFormat,
      },
      {
        label: "LayupA%",
        stat: "layupAttPct",
        statDef: "layupAttPctOpp",
        format: decFormat,
      },
      {
        label: "NL2%",
        stat: "nl2Pct",
        statDef: "nl2PctOpp",
        format: decFormat,
      },
      {
        label: "NL2A%",
        stat: "nl2AttPct",
        statDef: "nl2AttPctOpp",
        lowerIsBetter: true,
        format: decFormat,
      },
      {
        label: "3P%",
        stat: "3pPct",
        statDef: "3pPctOpp",
        format: decFormat,
      },
      {
        label: "3PA%",
        stat: "3paPct",
        statDef: "3paPctOpp",
        format: decFormat,
      },
      {
        label: "FT%",
        stat: "ftPct",
        statDef: "ftPctOpp",
        format: decFormat,
      },
      {
        label: "FTA Rate",
        stat: "ftaPct",
        statDef: "ftaPctOpp",
        format: decFormat,
      },
      {
        label: "AST / FGM%",
        stat: "astFgmPct",
        statDef: "astFgmPctOpp",
        format: decFormat,
      },
    ],
    [
      {
        label: "Half-court xPPP",
        stat: "hcXPPP",
        statDef: "hcXPPPOpp",
        format: decFormat2,
      },
      {
        label: "Transition xPPP",
        stat: "transXPPP",
        statDef: "transXPPPOpp",
        format: decFormat2,
      },
      {
        label: "Transition Rate",
        stat: "transRate",
        statDef: "transRateOpp",
        format: dec100Format,
      },
      {
        label: "ATO xPPP",
        stat: "atoXPPP",
        statDef: "atoXPPPOpp",
        format: decFormat2,
      },
      {
        label: "SOB xPPP",
        stat: "sobXPPP",
        statDef: "sobXPPPOpp",
        format: decFormat2,
      },
      {
        label: "BOB xPPP",
        stat: "bobXPPP",
        statDef: "bobXPPPOpp",
        format: decFormat2,
      },
      {
        label: "Zone xPPP",
        stat: "zonexPPP",
        statDef: "zonexPPPOpp",
        format: decFormat2,
      },
      {
        label: "Crashers / Shot",
        stat: "crashersPerShot",
        statDef: "crashersPerShotOpp",
        format: decFormat2,
      },
      {
        label: "Shots / Possession",
        stat: "shotsPerPoss",
        statDef: "shotsPerPossOpp",
        format: decFormat2,
      },
    ],
  ];

  return (
    <div>
      <Form.Label>Compare Against</Form.Label>
      <div>
        <ToggleButtonGroup
          name="game-team-stats-compare"
          type="radio"
          value={compareTeam}
          onChange={setCompareTeam}
        >
          <ToggleButton id="game-team-stats-away" value={"away"}>
            {`${away.teamabbreviation} Season Averages`}
          </ToggleButton>
          <ToggleButton id="game-team-stats-home" value={"home"}>
            {`${home.teamabbreviation} Season Averages`}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Row>
        {groups.map((group, i) => {
          return (
            <Col key={i} md={4}>
              <table
                style={{
                  tableLayout: "fixed",
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <colgroup>
                  <col style={{ width: 100 }} />
                  <col style={{ width: 50 }} />
                  <col style={{ width: 55 }} />
                  <col style={{ width: "auto" }} />
                </colgroup>
                <thead>
                  <tr style={{ borderBottom: "2px solid #ddd" }}>
                    <th style={{ padding: 7 }}>Stat</th>
                    <th style={{ padding: 7 }}>Team</th>
                    <th style={{ padding: 7, textAlign: "right" }}>Value</th>
                    <th style={{ padding: 7 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {group.map((stat, i) => {
                    const homeValue = homeStats[stat.stat] as number;
                    const awayValue = awayStats[stat.stat] as number;
                    const homeIsBetter = stat.lowerIsBetter
                      ? homeValue < awayValue
                      : homeValue > awayValue;

                    const leagueMin = Math.min(
                      ...leagueGames.map((lg) =>
                        Math.min(
                          ...([lg[stat.statDef], lg[stat.stat]].filter(
                            (v) => v !== null
                          ) as number[])
                        )
                      )
                    );
                    const leagueMax = Math.max(
                      ...leagueGames.map((lg) =>
                        Math.max(
                          ...([lg[stat.statDef], lg[stat.stat]].filter(
                            (v) => v !== null
                          ) as number[])
                        )
                      )
                    );
                    const leagueAvg = average((i) => i[stat.stat], leagueStats);

                    return (
                      <tr
                        key={i}
                        style={{
                          backgroundColor:
                            i % 2 === 1 ? "white" : "rgba(0,0,0,.04)",
                        }}
                      >
                        <td
                          style={{
                            verticalAlign: "top",
                            padding: 7,
                          }}
                        >
                          {stat.label}
                        </td>
                        <td style={{ padding: 7 }}>
                          <div
                            style={{
                              fontWeight: homeIsBetter ? "bold" : undefined,
                            }}
                          >
                            <div>{home.teamabbreviation}</div>
                          </div>
                          <div
                            style={{
                              fontWeight: homeIsBetter ? undefined : "bold",
                            }}
                          >
                            {away.teamabbreviation}
                          </div>
                        </td>
                        <td style={{ padding: 7, textAlign: "right" }}>
                          <div
                            style={{
                              fontWeight: homeIsBetter ? "bold" : undefined,
                            }}
                          >
                            <div>{stat.format(homeValue)}</div>
                          </div>
                          <div
                            style={{
                              fontWeight: homeIsBetter ? undefined : "bold",
                            }}
                          >
                            <div>{stat.format(awayValue)}</div>
                          </div>
                        </td>
                        <td style={{ paddingTop: 7, paddingBottom: 7 }}>
                          <ParentSize>
                            {({ width }) => (
                              <>
                                <div>
                                  <TwoTeamStatComparison
                                    width={width}
                                    teamAbbr={home.teamabbreviation}
                                    oppTeamAbbr={away.teamabbreviation}
                                    value={homeValue}
                                    oppValue={awayValue}
                                    offAvgVal={
                                      homeLeagueTeamStats
                                        ? homeLeagueTeamStats[stat.stat]
                                        : null
                                    }
                                    defAvgVal={
                                      awayLeagueTeamStats
                                        ? awayLeagueTeamStats[stat.statDef]
                                        : null
                                    }
                                    lowerIsBetter={!!stat.lowerIsBetter}
                                    leagueMin={leagueMin}
                                    lgAvgValue={leagueAvg}
                                    leagueMax={leagueMax}
                                    defaultCompare={
                                      compareTeam === "home" ? "off" : "def"
                                    }
                                    format={stat.format}
                                    offColors={getContrastingTeamColor(
                                      home.teamid,
                                      away.teamid
                                    )}
                                    defColors={getContrastingTeamColor(
                                      away.teamid,
                                      home.teamid
                                    )}
                                  />
                                </div>
                                <div>
                                  <TwoTeamStatComparison
                                    width={width}
                                    teamAbbr={away.teamabbreviation}
                                    oppTeamAbbr={home.teamabbreviation}
                                    value={awayValue}
                                    oppValue={homeValue}
                                    offAvgVal={
                                      awayLeagueTeamStats
                                        ? awayLeagueTeamStats[stat.stat]
                                        : null
                                    }
                                    defAvgVal={
                                      homeLeagueTeamStats
                                        ? homeLeagueTeamStats[stat.statDef]
                                        : null
                                    }
                                    lowerIsBetter={!!stat.lowerIsBetter}
                                    defaultCompare={
                                      compareTeam === "away" ? "off" : "def"
                                    }
                                    format={stat.format}
                                    leagueMin={leagueMin}
                                    lgAvgValue={leagueAvg}
                                    leagueMax={leagueMax}
                                    offColors={getContrastingTeamColor(
                                      away.teamid,
                                      home.teamid
                                    )}
                                    defColors={getContrastingTeamColor(
                                      home.teamid,
                                      away.teamid
                                    )}
                                  />
                                </div>
                              </>
                            )}
                          </ParentSize>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          );
        })}
      </Row>
      <TableNote
        note={`The tail of the comets represents the season average
          for the selected team (currently ${
            compareTeam === "home"
              ? home.teamabbreviation
              : away.teamabbreviation
          }), while the head represents
          the value in the game.
          The color shows which team did better compared to the season average.
          The difference between the game value and the season average is shown on hover.`}
      />
    </div>
  );
}

function TwoTeamStatComparison(props: {
  width: number;
  teamAbbr: string;
  oppTeamAbbr: string;
  value: number | null;
  oppValue: number | null;
  offAvgVal: number | null;
  defAvgVal: number | null;
  lowerIsBetter: boolean;
  leagueMin: number;
  lgAvgValue: number;
  leagueMax: number;
  defaultCompare: "off" | "def";
  format: (n: number | null) => string;
  offColors: { primary: string; secondary: string };
  defColors: { primary: string; secondary: string };
}) {
  const {
    width,
    teamAbbr,
    oppTeamAbbr,
    value,
    oppValue,
    offAvgVal,
    defAvgVal,
    lowerIsBetter,
    leagueMin,
    lgAvgValue,
    leagueMax,
    defaultCompare,
    format,
    offColors,
    defColors,
  } = props;

  const [hoverTeam, setHoverTeam] = useState<"off" | "def" | null>(null);
  const target = useRef(null);

  const handleMouseEnter = (compareToOff: boolean) => {
    setHoverTeam(compareToOff ? "off" : "def");
  };
  const handleMouseLeave = () => {
    setHoverTeam(null);
  };

  const svgWidth = width;
  const svgHeight = 16;

  if (value === null || offAvgVal === null || defAvgVal === null) {
    return null;
  }

  // Figure out what x value should map to the start and end of the chart. To
  // ensure that the league median is always in the middle we make sure that the
  // distance from min -> median is the same as median -> max.

  const distsToMedian = (
    [leagueMin, leagueMax, value, oppValue, defAvgVal, offAvgVal].filter(
      (v) => v !== null
    ) as number[]
  ).map((d) => Math.abs(d - lgAvgValue));
  const maxDistFromMedian = Math.max(...distsToMedian);

  const xMid = lgAvgValue;
  const xLow = xMid - maxDistFromMedian;
  const xHigh = xMid + maxDistFromMedian;

  const circleRadius = 4;

  // Make sure if a circle appears at the edge of the chart it doesn't get
  // clipped.
  const chartPadding = circleRadius + 2;

  const chartWidth = svgWidth - 2 * chartPadding;

  const defValueX =
    chartPadding + ((defAvgVal - xLow) / (xHigh - xLow)) * chartWidth;
  const offValueX =
    chartPadding + ((offAvgVal - xLow) / (xHigh - xLow)) * chartWidth;
  const valueX = chartPadding + ((value - xLow) / (xHigh - xLow)) * chartWidth;

  // The midpoint between the offValue and the defValue - we'll use this to
  // create the two different mouse enter/leave regions.
  const offDefMidValueX = (offValueX + defValueX) / 2;

  const comparisonTeam = hoverTeam || defaultCompare;

  const offCompareColors =
    (valueX > offValueX && !lowerIsBetter) ||
    (valueX < offValueX && lowerIsBetter)
      ? { fill: offColors.primary, stroke: offColors.secondary }
      : { fill: defColors.primary, stroke: defColors.secondary };

  const defCompareColors =
    (valueX > defValueX && !lowerIsBetter) ||
    (valueX < defValueX && lowerIsBetter)
      ? { fill: offColors.primary, stroke: offColors.secondary }
      : { fill: defColors.primary, stroke: defColors.secondary };

  const defaultColors =
    comparisonTeam === "off" ? offCompareColors : defCompareColors;

  return (
    <div style={{ position: "relative" }}>
      <svg ref={target} style={{ width: svgWidth, height: svgHeight }}>
        {/* Def team avg value. */}
        <line
          x1={defValueX}
          y1={svgHeight * 0.2}
          x2={defValueX}
          y2={svgHeight * 0.8}
          strokeWidth={hoverTeam === "def" ? 3 : 2}
          stroke={defColors.primary}
          opacity={hoverTeam === "def" ? 1 : 0.5}
        />
        {/* Off team avg value. */}
        <line
          x1={offValueX}
          y1={svgHeight * 0.2}
          x2={offValueX}
          y2={svgHeight * 0.8}
          strokeWidth={hoverTeam === "off" ? 3 : 2}
          stroke={offColors.primary}
          opacity={hoverTeam === "off" ? 1 : 0.5}
        />
        {/* Actual value for game */}
        <Comet
          y={svgHeight / 2}
          r={circleRadius}
          xTail={comparisonTeam === "off" ? offValueX : defValueX}
          xHead={valueX}
          fillColor={
            hoverTeam === null
              ? defaultColors.fill
              : hoverTeam === "off"
              ? offCompareColors.fill
              : defCompareColors.fill
          }
          outlineColor={
            hoverTeam === null
              ? defaultColors.stroke
              : hoverTeam === "off"
              ? offCompareColors.stroke
              : defCompareColors.stroke
          }
        />
        <rect
          x={chartPadding}
          y={0}
          width={offDefMidValueX}
          height={svgHeight}
          fillOpacity={0}
          onMouseEnter={() => handleMouseEnter(offValueX < defValueX)}
          onMouseLeave={handleMouseLeave}
        />
        <rect
          x={offDefMidValueX}
          y={0}
          width={chartWidth - offDefMidValueX + chartPadding}
          height={svgHeight}
          fillOpacity={0}
          onMouseEnter={() => handleMouseEnter(offValueX > defValueX)}
          onMouseLeave={handleMouseLeave}
        />
      </svg>
      <Overlay target={target.current} show={hoverTeam !== null}>
        <div
          style={{
            whiteSpace: "nowrap",
            fontSize: "0.9em",
            lineHeight: 0.5,
            pointerEvents: "none",
            position: "absolute",
            padding: 4,
            background: "#eee",
            border: "1px solid #ddd",
            borderRadius: 2,
          }}
        >
          <div style={{ lineHeight: 1, marginBottom: 4 }}>
            <b>
              {makePlusMinus(format)(
                value - (hoverTeam === "off" ? offAvgVal : defAvgVal)
              )}
            </b>
          </div>
          <div>
            {hoverTeam === "off" ? `${teamAbbr} OFF` : `${oppTeamAbbr} DEF`}
            {" avg"}
          </div>
        </div>
      </Overlay>
    </div>
  );
}
