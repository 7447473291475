import React, { useMemo, useState } from "react";

import { Table, createColumnHelper } from "../core/Table";
import { GameExpectedStats } from "../../../shared/routers/GameRouter";
import {
  decFormat,
  decFormat2,
  pctFormat,
  plusMinusFormat,
} from "../../util/Format";
import { groupBy } from "../../../shared/util/Collections";

const columnHelper = createColumnHelper<{
  stat: string;
  expected: number | null;
  xPtsAboveLeague: number | null;
  actual: number | null;
  diff: number | null;
  fmt: (n: number | null) => string;
}>();

export function GameXPtsBreakdownTable(props: { data?: GameExpectedStats[] }) {
  const { data } = props;
  const [period, setPeriod] = useState<number>(0);

  const options = [
    { value: 0, label: "Full Game" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ];

  const otPeriods = [
    ...new Set((data || []).map((d) => d.period).filter((p) => p > 4)),
  ].sort();

  for (const ot of otPeriods) {
    options.push({ value: ot, label: `OT${ot - 4}` });
  }

  const filteredData = (data || []).filter((d) => d.period === period);

  const dataByTeam = groupBy(data || [], (d) => d.team);

  if (!data || data.length === 0) return null;

  return (
    <>
      <div>
        <select
          value={period}
          onChange={(evt) => setPeriod(parseInt(evt.target.value))}
        >
          {options.map((po) => (
            <option value={po.value} key={po.value}>
              {po.label}
            </option>
          ))}
        </select>
      </div>
      <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
        {(filteredData || [])
          .sort((a, b) => (a.team > b.team ? 1 : -1))
          .map((d, i) => (
            <TeamTable stats={d} key={i} />
          ))}
      </div>
      <div>
        <b>xPPP by Quarter</b>
        <table>
          <thead>
            <tr>
              <th style={{ padding: 8, textAlign: "right" }}></th>
              {options
                .filter((o) => o.value > 0)
                .map((o) => (
                  <th style={{ padding: 8, textAlign: "right" }} key={o.value}>
                    {o.label}
                  </th>
                ))}
              <th style={{ padding: 8, textAlign: "right" }}>T</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(dataByTeam)
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(([key, val]) => (
                <tr key={key}>
                  <td style={{ padding: 8 }}>
                    <b>{key}</b>
                  </td>
                  {val
                    .sort((a, b) => {
                      // Want this to be in asc order but 0 is at end.
                      if (a.period === 0) return 1;
                      if (b.period === 0) return -1;
                      return a.period - b.period;
                    })
                    .map((d, i) => {
                      const isLast = i === val.length - 1;

                      return (
                        <td
                          style={{
                            textAlign: "right",
                            padding: 8,
                            fontWeight: isLast ? "bold" : undefined,
                          }}
                          key={d.period}
                        >
                          {decFormat2(d.xPPP)}
                        </td>
                      );
                    })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

function TeamTable(props: { stats: GameExpectedStats }) {
  const { stats } = props;
  const teamName = stats.team;
  const data = [
    {
      stat: "First Shot PPS",
      expected: stats.first_shot_xpps,
      actual: stats.first_shot_actual_pps_exp_ft,
      xPtsAboveLeague: stats.first_shot_xPPS_pts_above_league,
      diff: stats.shooting_variance,
      fmt: decFormat2,
    },
    {
      stat: "OR PTS (/w xFT)",
      expected: stats.xpts_from_OR,
      actual: stats.pts_from_OR_exp_ft,
      xPtsAboveLeague: stats.xpts_from_OR_above_league,
      diff: stats.off_reb_variance,
      fmt: decFormat,
    },
    {
      stat: "TO%",
      expected: stats.xTOp || 0,
      actual: stats.TOp || 0,
      xPtsAboveLeague: stats.xto_above_league,
      diff: stats.turnover_variance,
      fmt: pctFormat,
    },
    {
      stat: "NSF PTS (/w xFT)",
      expected: stats.xpts_from_nsfs,
      actual: stats.pts_from_nsfs_exp_ft,
      xPtsAboveLeague: stats.xnsf_pts_above_league,
      diff: stats.nsf_variance,
      fmt: decFormat,
    },
    {
      stat: "FT%",
      expected: stats.xFTp,
      actual: stats.FTp,
      xPtsAboveLeague: 0,
      diff: stats.ft_variance,
      fmt: pctFormat,
    },
    {
      stat: "Total",
      expected: stats.xPPP,
      actual: stats.PPP,
      xPtsAboveLeague: stats.xPPP_above_league,
      diff: stats.total_pts_variance,
      fmt: decFormat2,
    },
  ];

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("stat", {
        header: () => teamName,
        cell: (info) => {
          if (info.row.original.stat === "Total") {
            return <b>{info.getValue()}</b>;
          }
          return info.getValue();
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("actual", {
        header: () => "Actual",
        cell: (info) => {
          if (info.row.original.stat === "Total") {
            return <b>{info.row.original.fmt(info.getValue())}</b>;
          }
          return info.row.original.fmt(info.getValue());
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("xPtsAboveLeague", {
        header: () => "Expected",
        cell: (info) => {
          if (info.row.original.stat === "Total") {
            return <b>{info.row.original.fmt(info.row.original.expected)}</b>;
          }
          return info.row.original.fmt(info.row.original.expected);
        },
        meta: { group: g++, colorDomain: [-0.08, 0.08], heatmap: true },
      }),
      columnHelper.accessor("diff", {
        header: () => "PTS Diff",
        cell: (info) => {
          if (info.row.original.stat === "Total") {
            return <b>{plusMinusFormat(info.getValue())}</b>;
          }
          return plusMinusFormat(info.getValue());
        },
        meta: { group: g++ },
      }),
    ];
  }, [teamName]);

  return (
    <Table
      data={data}
      columns={columns}
      autoWidth={true}
      showRowIndex={false}
    />
  );
}
