import React from "react";

import { TooltipItem } from "../core/TooltipItem";
import { pctFormat } from "../../util/Format";
import { getContrastingTeamColor } from "../../util/Colors";
import { CELTICS_TEAM_ID } from "../../constants/AppConstants";

export function GameWinProbBar(props: {
  xWinPctHome: number;
  xWinPctAway: number;
  homeTeamId: number;
  awayTeamId: number;
  homeTeamName: string;
  awayTeamName: string;
  baselinexWinPctHome: number;
}) {
  const {
    xWinPctHome,
    xWinPctAway,
    homeTeamId,
    awayTeamId,
    homeTeamName,
    awayTeamName,
    baselinexWinPctHome,
  } = props;
  const homeColor = getContrastingTeamColor(homeTeamId, awayTeamId);
  const awayColor = getContrastingTeamColor(awayTeamId, homeTeamId);

  const svgHeight = 36;
  const topPadding = 16;
  const bottomPadding = 4;
  const pillHeight = svgHeight - topPadding - bottomPadding;

  const showAwayOutOfPill = awayTeamId === CELTICS_TEAM_ID && xWinPctAway < 0.3;
  const showHomeOutofPill = homeTeamId === CELTICS_TEAM_ID && xWinPctHome < 0.3;

  const showAwayInPill =
    !showAwayOutOfPill &&
    (awayTeamId === CELTICS_TEAM_ID ||
      (homeTeamId !== CELTICS_TEAM_ID && xWinPctAway >= 0.5));
  const showHomeInPill =
    !showHomeOutofPill &&
    (homeTeamId === CELTICS_TEAM_ID ||
      (awayTeamId !== CELTICS_TEAM_ID && xWinPctHome >= 0.5));

  const xWinPct = getXWinPct(xWinPctAway, xWinPctHome, awayTeamId, homeTeamId);

  // The baselineWinPct corresponding to the xWinPct Team (or Celtics) that
  // will get printed.
  const baselinePct = getBaselineWinPct(
    xWinPctAway,
    xWinPctHome,
    baselinexWinPctHome,
    awayTeamId,
    homeTeamId
  );

  const leftGutter = showAwayOutOfPill ? 70 : 0;
  const rightGutter = showHomeOutofPill ? 70 : 0;
  const chartXPadding = 10;
  const pillWidth = 220;

  const chartWidth = pillWidth + leftGutter + rightGutter + chartXPadding * 2;
  const startX = chartXPadding + leftGutter;
  const xWinPctX = startX + xWinPctAway * pillWidth;
  const endX = chartWidth - chartXPadding - rightGutter;

  const baselineX = startX + (1 - baselinexWinPctHome) * pillWidth;

  const midY = topPadding + pillHeight / 2;

  const strokeWidth = 12;

  const baseLineColor = "white";

  // Use the baseline pct that corresponds to the team that ended with the
  // greater xWinPct. If Celtics are playing then always use them.
  const xWinTeamName =
    awayTeamId === CELTICS_TEAM_ID || homeTeamId === CELTICS_TEAM_ID
      ? "BOS"
      : xWinPctAway > xWinPctHome
      ? awayTeamName
      : homeTeamName;

  const xWinPctStr = pctFormat(xWinPct, 0);
  const xWinPctBaselineStr = pctFormat(baselinePct, 0);
  const samePctMsg = `Based on the strength of their rotations, and based on how both teams executed, ${xWinTeamName} was ${xWinPctStr} likely to win.`;
  const tooltipMsg =
    xWinPctStr === xWinPctBaselineStr
      ? samePctMsg
      : `Based on the strength of their rotations, ${xWinTeamName} was ${xWinPctBaselineStr} to win, but based on how both teams executed, ${xWinTeamName} was ${xWinPctStr} likely to win.`;

  return (
    <div style={{ position: "relative" }}>
      <TooltipItem
        noListItem={true}
        arrow={false}
        tooltip={<p style={{ textAlign: "left" }}>{tooltipMsg}</p>}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: chartWidth, height: svgHeight }}
        >
          {showAwayOutOfPill && (
            <text
              x={chartXPadding / 2}
              y={midY}
              dy="0.4em"
              textAnchor="start"
              fill={"white"}
              fontSize="12"
              fontWeight={600}
            >
              xWin: {pctFormat(xWinPctAway, 0)}
            </text>
          )}
          <line
            x1={startX}
            y1={midY}
            x2={endX}
            y2={midY}
            strokeWidth={strokeWidth + 4}
            stroke={"white"}
            strokeLinecap="round"
          />
          <circle
            cx={startX}
            cy={midY}
            r={strokeWidth / 2}
            fill={awayColor.primary}
            strokeWidth={1}
          />
          <line
            x1={startX}
            y1={midY}
            x2={xWinPctX}
            y2={midY}
            strokeWidth={strokeWidth}
            stroke={awayColor.primary}
          />
          {showAwayInPill && (
            <text
              x={(startX + xWinPctX) / 2}
              y={midY}
              dy="0.4em"
              textAnchor="middle"
              fill={contrastingTextColor(awayColor.primary)}
              fontSize="12"
              fontWeight={600}
            >
              xWin: {pctFormat(xWinPctAway, 0)}
            </text>
          )}
          <line
            x1={endX}
            y1={midY}
            x2={xWinPctX}
            y2={midY}
            strokeWidth={strokeWidth}
            stroke={homeColor.primary}
          />
          <circle
            cx={endX}
            cy={midY}
            r={strokeWidth / 2}
            fill={homeColor.primary}
            strokeWidth={1}
          />
          <line
            x1={xWinPctX - 1}
            y1={midY}
            x2={xWinPctX + 1}
            y2={midY}
            strokeWidth={strokeWidth}
            stroke={"white"}
          />
          {showHomeInPill && (
            <text
              x={(endX + xWinPctX) / 2}
              y={midY}
              dy="0.4em"
              textAnchor="middle"
              fill={contrastingTextColor(homeColor.primary)}
              fontSize="12"
              fontWeight={600}
            >
              xWin: {pctFormat(xWinPctHome, 0)}
            </text>
          )}
          {showHomeOutofPill && (
            <text
              x={chartWidth - chartXPadding / 2}
              y={midY}
              dy="0.4em"
              textAnchor="end"
              fill={"white"}
              fontSize="12"
              fontWeight={600}
            >
              xWin: {pctFormat(xWinPctHome, 0)}
            </text>
          )}
          <text
            x={baselineX}
            y={topPadding / 2 - 4}
            fontSize="10"
            fill={baseLineColor}
            dy="0.4em"
            dx={1.5}
            textAnchor="middle"
          >
            {pctFormat(baselinePct, 0)}
          </text>
          <polygon
            points={`${baselineX - 2} ${topPadding - 4 + 1}, ${baselineX + 2} ${
              topPadding - 4 + 1
            },${baselineX} ${topPadding}`}
            fill={baseLineColor}
            stroke={baseLineColor}
            strokeWidth={1.5}
          />
          <polygon
            points={`${baselineX - 2} ${svgHeight - 1}, ${baselineX + 2} ${
              svgHeight - 1
            },${baselineX} ${svgHeight - bottomPadding}`}
            fill={baseLineColor}
            stroke={baseLineColor}
            strokeWidth={1.5}
          ></polygon>
        </svg>
      </TooltipItem>
    </div>
  );
}

function contrastingTextColor(colorStr: string): string {
  // Remove the hash symbol.
  const hexColor = colorStr.replace("#", "");

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white based on luminance
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}

function getXWinPct(
  xWinPctAway: number,
  xWinPctHome: number,
  awayTeamId: number,
  homeTeamId: number
) {
  if (awayTeamId === CELTICS_TEAM_ID) return xWinPctAway;
  if (homeTeamId === CELTICS_TEAM_ID) return xWinPctHome;
  return xWinPctAway > xWinPctHome ? xWinPctAway : xWinPctHome;
}

function getBaselineWinPct(
  xWinPctAway: number,
  xWinPctHome: number,
  baselinexWinPctHome: number,
  awayTeamId: number,
  homeTeamId: number
) {
  if (awayTeamId === CELTICS_TEAM_ID) return 1 - baselinexWinPctHome;
  if (homeTeamId === CELTICS_TEAM_ID) return baselinexWinPctHome;
  return xWinPctAway > xWinPctHome
    ? 1 - baselinexWinPctHome
    : baselinexWinPctHome;
}
