import React, { useMemo, useState } from "react";

import { Table, createColumnHelper, SortingState } from "../core/Table";
import {
  SecondSpectrumPossession,
  SecondSpectrumShot,
  SecondSpectrumTurnover,
  SecondSpectrumFreeThrow,
  SecondSpectrumRebound,
  FourFactorsComparisonGame,
  SecondSpectrumChance,
} from "../../../shared/routers/LiveGameRouter";
import { CrashAttempt } from "../../../shared/routers/GameRouter";
import {
  percentileFormat,
  decFormat2,
  pctFormat,
  dec100Format,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { realReboundOpp, isFga } from "../../util/LivePageUtil";

interface FourFactorsRow {
  team: string;
  ppp: number;
  efg: number;
  tov: number;
  orb: number;
  ftr: number;
  transRate: number;
  crashersPerShot: number;
}

const fourFactorColumnHelper = createColumnHelper<FourFactorsRow>();

export function LiveGameFourFactorsTable(props: {
  homeTeamId: string;
  awayTeamId: string;
  homeTeamIds: number;
  awayTeamIds: number;
  homeTeam: string;
  awayTeam: string;
  possessions: SecondSpectrumPossession[];
  shots: SecondSpectrumShot[];
  freeThrows: SecondSpectrumFreeThrow[];
  turnovers: SecondSpectrumTurnover[];
  rebounds: SecondSpectrumRebound[];
  compGames: FourFactorsComparisonGame[];
  crashAttempts: CrashAttempt[];
  chances: SecondSpectrumChance[];
}) {
  const {
    possessions,
    shots,
    freeThrows,
    turnovers,
    rebounds,
    homeTeamId,
    awayTeamId,
    homeTeamIds,
    awayTeamIds,
    homeTeam,
    awayTeam,
    compGames,
    crashAttempts,
    chances,
  } = props;

  const [sorting, setSorting] = useState<SortingState>();

  const pps = compGames.map((d) => d.ppp).sort((a, b) => a - b);
  const efgs = compGames.map((d) => d.efg).sort((a, b) => a - b);
  const tovRates = compGames.map((d) => d.tovRate).sort((a, b) => b - a);
  const orbRates = compGames.map((d) => d.orbRate).sort((a, b) => a - b);
  const ftRates = compGames.map((d) => d.ftRate).sort((a, b) => a - b);

  const freeThrowMap = useMemo(() => {
    return new Map(freeThrows.map((ft) => [ft.pbpId, ft]));
  }, [freeThrows]);

  const homePossessions = useMemo(
    () => possessions.filter((p) => p.offTeamId === homeTeamId),
    [possessions, homeTeamId]
  );
  const awayPossessions = useMemo(
    () => possessions.filter((p) => p.offTeamId === awayTeamId),
    [possessions, awayTeamId]
  );

  const homeShots = useMemo(
    () => shots.filter((s) => s.offTeamId === homeTeamId),
    [shots, homeTeamId]
  );

  const awayShots = useMemo(
    () => shots.filter((s) => s.offTeamId === awayTeamId),
    [shots, awayTeamId]
  );

  const homeFts = useMemo(
    () => freeThrows.filter((ft) => ft.offTeamId === homeTeamId),
    [freeThrows, homeTeamId]
  );

  const awayFts = useMemo(
    () => freeThrows.filter((ft) => ft.offTeamId === awayTeamId),
    [freeThrows, awayTeamId]
  );

  const homeTovs = useMemo(
    () => turnovers.filter((t) => t.offTeamId === homeTeamId),
    [turnovers, homeTeamId]
  );

  const awayTovs = useMemo(
    () => turnovers.filter((t) => t.offTeamId === awayTeamId),
    [turnovers, awayTeamId]
  );

  const homeRebounds = useMemo(
    () =>
      rebounds.filter(
        (r) => r.teamId === homeTeamId && realReboundOpp(r, freeThrowMap)
      ),
    [rebounds, homeTeamId, freeThrowMap]
  );

  const awayRebounds = useMemo(
    () =>
      rebounds.filter(
        (r) => r.teamId === awayTeamId && realReboundOpp(r, freeThrowMap)
      ),
    [rebounds, awayTeamId, freeThrowMap]
  );

  const transRate = compGames.map((d) => d.transRate).sort((a, b) => a - b);
  const crashersPerShot = compGames
    .map((d) => d.crashersPerShot)
    .sort((a, b) => a - b);

  const homeChances = useMemo(
    () => chances.filter((c) => c.offTeamId === homeTeamId),
    [chances, homeTeamId]
  );
  const homePossessionIds = [
    ...new Set(homeChances.map((hc) => hc.possessionId)),
  ];
  const homeTransitionPossessionIds = [
    ...new Set(
      homeChances.filter((hc) => hc.transition).map((hc) => hc.possessionId)
    ),
  ];

  const awayChances = useMemo(
    () => chances.filter((c) => c.offTeamId === awayTeamId),
    [chances, awayTeamId]
  );
  const awayPossessionIds = [
    ...new Set(awayChances.map((hc) => hc.possessionId)),
  ];
  const awayTransitionPossessionIds = [
    ...new Set(
      awayChances.filter((hc) => hc.transition).map((hc) => hc.possessionId)
    ),
  ];

  const homeCrashAttempts = useMemo(
    () => crashAttempts.filter((c) => c.teamId === homeTeamIds),
    [crashAttempts, homeTeamIds]
  );

  const awayCrashAttempts = useMemo(
    () => crashAttempts.filter((c) => c.teamId === awayTeamIds),
    [crashAttempts, awayTeamIds]
  );

  const data = [
    {
      team: homeTeam,
      ppp:
        (homeShots.reduce(
          (a, b) => a + (b.outcome ? (b.three ? 3 : 2) : 0),
          0
        ) +
          homeFts.filter((ft) => ft.outcome).length) /
        homePossessions.length,
      efg:
        homeShots.reduce(
          (a, b) => a + (b.outcome ? (b.three ? 1.5 : 1) : 0),
          0
        ) / homeShots.filter(isFga).length,
      tov: homeTovs.length / homePossessions.length,
      orb:
        homeRebounds.filter((r) => !r.defensive).length /
        (homeRebounds.filter((r) => !r.defensive).length +
          awayRebounds.filter((r) => r.defensive).length),
      ftr: homeFts.length / homeShots.filter(isFga).length,
      transRate:
        homePossessionIds.length === 0
          ? 0
          : homeTransitionPossessionIds.length / homePossessionIds.length,
      crashersPerShot: homeCrashAttempts.length
        ? (5 * homeCrashAttempts.filter((c) => c.action === "Crashed").length) /
          homeCrashAttempts.length
        : 0,
    },
    {
      team: awayTeam,
      ppp:
        (awayShots.reduce(
          (a, b) => a + (b.outcome ? (b.three ? 3 : 2) : 0),
          0
        ) +
          awayFts.filter((ft) => ft.outcome).length) /
        awayPossessions.length,
      efg:
        awayShots.reduce(
          (a, b) => a + (b.outcome ? (b.three ? 1.5 : 1) : 0),
          0
        ) / awayShots.filter(isFga).length,
      tov: awayTovs.length / awayPossessions.length,
      orb:
        awayRebounds.filter((r) => !r.defensive).length /
        (awayRebounds.filter((r) => !r.defensive).length +
          homeRebounds.filter((r) => r.defensive).length),
      ftr: awayFts.length / awayShots.filter(isFga).length,
      transRate:
        awayPossessionIds.length === 0
          ? 0
          : awayTransitionPossessionIds.length / awayPossessionIds.length,
      crashersPerShot: awayCrashAttempts.length
        ? (5 * awayCrashAttempts.filter((c) => c.action === "Crashed").length) /
          awayCrashAttempts.length
        : 0,
    },
  ];

  const columns = useMemo(() => {
    let g = 0;
    return [
      fourFactorColumnHelper.accessor("team", {
        header: () => "Team",
        meta: { group: g++, textAlign: "left" },
      }),
      fourFactorColumnHelper.accessor((row) => getPercentile(row.ppp, pps), {
        id: "pppPercentile",
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        meta: {
          colorDomain: [0, 1],
          heatmap: true,
          group: g,
        },
      }),
      fourFactorColumnHelper.accessor("ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g++,
        },
      }),
      fourFactorColumnHelper.accessor((row) => getPercentile(row.efg, efgs), {
        id: "efgPercentile",
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        meta: {
          colorDomain: [0, 1],
          heatmap: true,
          group: g,
        },
      }),
      fourFactorColumnHelper.accessor("efg", {
        header: () => "eFG%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g++,
        },
      }),
      fourFactorColumnHelper.accessor(
        (row) => getPercentile(row.tov, tovRates, true),
        {
          id: "tovPercentile",
          header: () => "",
          cell: (info) => percentileFormat(info.getValue()),
          meta: {
            colorDomain: [0, 1],
            heatmap: true,
            group: g,
          },
        }
      ),
      fourFactorColumnHelper.accessor("tov", {
        header: () => "TOV%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Min,
          group: g++,
        },
      }),
      fourFactorColumnHelper.accessor(
        (row) => getPercentile(row.orb, orbRates),
        {
          id: "orbPercentile",
          header: () => "",
          cell: (info) => percentileFormat(info.getValue()),
          meta: {
            colorDomain: [0, 1],
            heatmap: true,
            group: g,
          },
        }
      ),
      fourFactorColumnHelper.accessor("orb", {
        header: () => "OREB%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g++,
        },
      }),
      fourFactorColumnHelper.accessor(
        (row) => getPercentile(row.ftr, ftRates),
        {
          id: "ftrPercentile",
          header: () => "",
          cell: (info) => percentileFormat(info.getValue()),
          meta: {
            colorDomain: [0, 1],
            heatmap: true,
            group: g,
          },
        }
      ),
      fourFactorColumnHelper.accessor("ftr", {
        header: () => "FT Rate",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g++,
        },
      }),
      fourFactorColumnHelper.accessor(
        (row) => getPercentile(row.transRate, transRate),
        {
          id: "transRatePercentile",
          header: () => "",
          cell: (info) => percentileFormat(info.getValue()),
          meta: {
            colorDomain: [0, 1],
            heatmap: true,
            group: g,
          },
        }
      ),
      fourFactorColumnHelper.accessor("transRate", {
        header: () => "Transition Rate",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g++,
        },
      }),
      fourFactorColumnHelper.accessor(
        (row) => getPercentile(row.crashersPerShot, crashersPerShot),
        {
          id: "crahsersPerShotPercentile",
          header: () => "",
          cell: (info) => percentileFormat(info.getValue()),
          meta: {
            colorDomain: [0, 1],
            heatmap: true,
            group: g,
          },
        }
      ),
      fourFactorColumnHelper.accessor("crashersPerShot", {
        header: () => "Crashers/Shot",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g++,
        },
      }),
    ];
  }, [crashersPerShot, efgs, ftRates, orbRates, pps, tovRates, transRate]);

  return (
    <Table
      data={data}
      columns={columns}
      autoWidth={true}
      showRowIndex={false}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
}

function getPercentile(value: number, values: number[], reverse = false) {
  const index = reverse
    ? values.findIndex((v) => v <= value)
    : values.findIndex((v) => v >= value);
  if (index === -1) {
    return 1;
  }
  return index / values.length;
}
